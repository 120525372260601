import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import axiosInstance from 'untils'
import { AxiosResponse } from 'axios'
import { Campaign, CampaignData, CampaignState } from 'features/campaign/types'
const campaignState: CampaignState = {
  selectedCampaign: null,
  campaigns: [],
  campaignsSelect: [],
  total: 0,
  error: null,
  loading: false,
}
export const getCampaignsList = createAsyncThunk<
  { items: Campaign[]; total: number },
  { accountId: number; page?: number; limit?: number; crmType?: number },
  { rejectValue: void }
>('getCampaignsList', async ({ page, limit, accountId }) => {
  const response = await axiosInstance.get<void, AxiosResponse<{ items: Campaign[]; total: number }>>(`/campaign`, {
    params: {
      page,
      limit,
      accountId,
    },
  })
  return response.data
})

export const getCampaignsListSelect = createAsyncThunk<{ items: Campaign[] }, number, { rejectValue: void }>(
  'getCampaignsListSelect',
  async accountId => {
    const response = await axiosInstance.get<void, AxiosResponse<{ items: Campaign[]; total: number }>>(`/campaign`, {
      params: {
        page: 1,
        limit: 10000,
        accountId,
      },
    })
    return response.data
  },
)

export const getCampaign = createAsyncThunk<Campaign, number, { rejectValue: void }>('getCampaign', async id => {
  const response = await axiosInstance.get<void, AxiosResponse<Campaign>>(`/campaign/${id}`)
  return response.data
})

export const createCampaign = createAsyncThunk<Campaign, CampaignData, { rejectValue: void }>(
  'createCampaign',
  async campaign => {
    const response = await axiosInstance.post<void, AxiosResponse<Campaign>>(`/campaign`, campaign)
    return response.data
  },
)

export const updateCampaign = createAsyncThunk<Campaign, Campaign, { rejectValue: void }>(
  'updateCampaign',
  async campaign => {
    const response = await axiosInstance.put<void, AxiosResponse<Campaign>>(`/campaign/${campaign.id}`, campaign)
    return response.data
  },
)
export const deleteCampaign = createAsyncThunk<boolean, { id: number; accountId: number }, { rejectValue: void }>(
  'deleteCampaign',
  async ({ id, accountId }, { dispatch }) => {
    const response = await axiosInstance.delete<void, AxiosResponse<boolean>>(`/campaign/${id}`)
    dispatch(checkSelectedCampaign(id))
    return response.data
  },
)

export const importCampaigns = createAsyncThunk<boolean, number, { rejectValue: void }>(
  'importCampaigns',
  async accountId =>
    await axiosInstance.post<void, boolean>(`/campaign/import`, {
      accountId: accountId,
    }),
)

export const campaignSlice = createSlice({
  name: 'campaign',
  initialState: campaignState,
  reducers: {
    resetCampaign(state) {
      state.selectedCampaign = null
    },
    checkSelectedCampaign(state, action) {
      if (state.selectedCampaign?.id === action.payload) {
        state.selectedCampaign = null
      }
    },
  },
  extraReducers: builder => {
    builder.addCase(getCampaignsList.pending, state => {
      state.loading = true
      state.error = null
    })
    builder.addCase(getCampaignsList.rejected, (state, action) => {
      state.error = action.error.message ? action.error.message : 'Unknown error'
      state.loading = false
      state.campaigns = []
      state.total = 0
    })
    builder.addCase(getCampaignsList.fulfilled, (state, action) => {
      state.error = null
      state.loading = false
      state.campaigns = action.payload.items
      state.total = action.payload.total
    })

    builder.addCase(getCampaignsListSelect.pending, state => {
      state.loading = true
      state.error = null
    })
    builder.addCase(getCampaignsListSelect.rejected, (state, action) => {
      state.error = action.error.message ? action.error.message : 'Unknown error'
      state.loading = false
      state.campaignsSelect = []
    })
    builder.addCase(getCampaignsListSelect.fulfilled, (state, action) => {
      state.error = null
      state.loading = false
      state.campaignsSelect = action.payload.items
    })

    builder.addCase(getCampaign.pending, state => {
      state.loading = true
      state.error = null
    })
    builder.addCase(getCampaign.rejected, (state, action) => {
      state.error = action.error.message ? action.error.message : 'Unknown error'
      state.loading = false
      state.selectedCampaign = null
    })
    builder.addCase(getCampaign.fulfilled, (state, action) => {
      state.error = null
      state.loading = false
      state.selectedCampaign = action.payload
    })

    builder.addCase(createCampaign.pending, state => {
      state.loading = true
      state.error = null
    })
    builder.addCase(createCampaign.rejected, (state, action) => {
      state.error = action.error.message ? action.error.message : 'Unknown error'
      state.loading = false
      state.selectedCampaign = null
    })
    builder.addCase(createCampaign.fulfilled, (state, action) => {
      state.error = null
      state.loading = false
      state.selectedCampaign = action.payload
    })

    builder.addCase(updateCampaign.pending, state => {
      state.loading = true
      state.error = null
    })
    builder.addCase(updateCampaign.rejected, (state, action) => {
      state.error = action.error.message ? action.error.message : 'Unknown error'
      state.loading = false
    })
    builder.addCase(updateCampaign.fulfilled, (state, action) => {
      state.error = null
      state.loading = false
      state.selectedCampaign = action.payload
    })

    builder.addCase(deleteCampaign.pending, state => {
      state.loading = true
      state.error = null
    })
    builder.addCase(deleteCampaign.rejected, (state, action) => {
      state.error = action.error.message ? action.error.message : 'Unknown error'
      state.loading = false
    })
    builder.addCase(deleteCampaign.fulfilled, (state, action) => {
      state.error = null
      state.loading = false
    })

    builder.addCase(importCampaigns.fulfilled, (state, action) => {
      state.error = null
      state.loading = false
    })
    builder.addCase(importCampaigns.pending, state => {
      state.loading = true
      state.error = null
    })
    builder.addCase(importCampaigns.rejected, (state, action) => {
      state.error = action.error.message ? action.error.message : 'Unknown error'
      state.loading = false
    })
  },
})
export const { resetCampaign, checkSelectedCampaign } = campaignSlice.actions
export default campaignSlice.reducer
