import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import axiosInstance from 'untils'
import { AxiosResponse } from 'axios'
import { Product, ProductData, ProductState } from 'features/product/types'
const productState: ProductState = {
  selectedProduct: null,
  products: [],
  productsSelect: [],
  total: 0,
  error: null,
  loading: false,
}
export const getProductsList = createAsyncThunk<
  { items: Product[]; total: number },
  { accountId: number; page?: number; limit?: number; crmType?: number },
  { rejectValue: void }
>('getProductsList', async ({ page, limit, accountId }) => {
  const response = await axiosInstance.get<void, AxiosResponse<{ items: Product[]; total: number }>>(`/product`, {
    params: {
      page,
      limit,
      accountId,
    },
  })
  return response.data
})
export const getProductsListSelect = createAsyncThunk<
  { items: Product[]; total: number },
  number,
  { rejectValue: void }
>('getProductsListSelect', async accountId => {
  const response = await axiosInstance.get<void, AxiosResponse<{ items: Product[]; total: number }>>(`/product`, {
    params: {
      page: 1,
      limit: 10000,
      accountId,
    },
  })
  return response.data
})
export const getProduct = createAsyncThunk<Product, number, { rejectValue: void }>('getProduct', async id => {
  const response = await axiosInstance.get<void, AxiosResponse<Product>>(`/product/${id}`)
  return response.data
})

export const createProduct = createAsyncThunk<Product, ProductData, { rejectValue: void }>(
  'createProduct',
  async product => {
    const response = await axiosInstance.post<void, AxiosResponse<Product>>(`/product`, product)
    return response.data
  },
)

export const updateProduct = createAsyncThunk<Product, Product, { rejectValue: void }>(
  'updateProduct',
  async product => {
    const response = await axiosInstance.put<void, AxiosResponse<Product>>(`/product/${product.id}`, product)
    return response.data
  },
)
export const deleteProduct = createAsyncThunk<boolean, { id: number; accountId: number }, { rejectValue: void }>(
  'deleteProduct',
  async ({ id, accountId }, { dispatch }) => {
    const response = await axiosInstance.delete<void, AxiosResponse<boolean>>(`/product/${id}`)
    dispatch(checkSelectedProduct(id))
    return response.data
  },
)

export const importProducts = createAsyncThunk<boolean, number, { rejectValue: void }>(
  'importProducts',
  async accountId =>
    await axiosInstance.post<void, boolean>(`/product/import`, {
      accountId: accountId,
    }),
)

export const productSlice = createSlice({
  name: 'product',
  initialState: productState,
  reducers: {
    resetProduct(state) {
      state.selectedProduct = null
    },
    checkSelectedProduct(state, action) {
      if (state.selectedProduct?.id === action.payload) {
        state.selectedProduct = null
      }
    },
  },
  extraReducers: builder => {
    builder.addCase(getProductsList.pending, state => {
      state.loading = true
      state.error = null
    })
    builder.addCase(getProductsList.rejected, (state, action) => {
      state.error = action.error.message ? action.error.message : 'Unknown error'
      state.loading = false
      state.products = []
      state.total = 0
    })
    builder.addCase(getProductsList.fulfilled, (state, action) => {
      state.error = null
      state.loading = false
      state.products = action.payload.items
      state.total = action.payload.total
    })

    builder.addCase(getProductsListSelect.pending, state => {
      state.loading = true
      state.error = null
    })
    builder.addCase(getProductsListSelect.rejected, (state, action) => {
      state.error = action.error.message ? action.error.message : 'Unknown error'
      state.loading = false
      state.productsSelect = []
    })
    builder.addCase(getProductsListSelect.fulfilled, (state, action) => {
      state.error = null
      state.loading = false
      state.productsSelect = action.payload.items
    })

    builder.addCase(getProduct.pending, state => {
      state.loading = true
      state.error = null
    })
    builder.addCase(getProduct.rejected, (state, action) => {
      state.error = action.error.message ? action.error.message : 'Unknown error'
      state.loading = false
      state.selectedProduct = null
    })
    builder.addCase(getProduct.fulfilled, (state, action) => {
      state.error = null
      state.loading = false
      state.selectedProduct = action.payload
    })

    builder.addCase(createProduct.pending, state => {
      state.loading = true
      state.error = null
    })
    builder.addCase(createProduct.rejected, (state, action) => {
      state.error = action.error.message ? action.error.message : 'Unknown error'
      state.loading = false
      state.selectedProduct = null
    })
    builder.addCase(createProduct.fulfilled, (state, action) => {
      state.error = null
      state.loading = false
      state.selectedProduct = action.payload
    })

    builder.addCase(updateProduct.pending, state => {
      state.loading = true
      state.error = null
    })
    builder.addCase(updateProduct.rejected, (state, action) => {
      state.error = action.error.message ? action.error.message : 'Unknown error'
      state.loading = false
    })
    builder.addCase(updateProduct.fulfilled, (state, action) => {
      state.error = null
      state.loading = false
      state.selectedProduct = action.payload
    })

    builder.addCase(deleteProduct.pending, state => {
      state.loading = true
      state.error = null
    })
    builder.addCase(deleteProduct.rejected, (state, action) => {
      state.error = action.error.message ? action.error.message : 'Unknown error'
      state.loading = false
    })
    builder.addCase(deleteProduct.fulfilled, (state, action) => {
      state.error = null
      state.loading = false
    })

    builder.addCase(importProducts.fulfilled, (state, action) => {
      state.error = null
      state.loading = false
    })
    builder.addCase(importProducts.pending, state => {
      state.loading = true
      state.error = null
    })
    builder.addCase(importProducts.rejected, (state, action) => {
      state.error = action.error.message ? action.error.message : 'Unknown error'
      state.loading = false
    })
  },
})
export const { resetProduct, checkSelectedProduct } = productSlice.actions
export default productSlice.reducer
