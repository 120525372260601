import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import Cookies from 'js-cookie'
import axiosInstance from 'untils'
import { AxiosResponse } from 'axios'
import { Client, ClientData, ClientState } from 'features/client/types'
const clientState: ClientState = {
  selectedClient: null,
  clients: [],
  clientsSelect: [],
  total: 0,
  error: null,
  loading: false,
}
export const getClientsList = createAsyncThunk<
  { items: Client[]; total: number },
  { page?: number; limit?: number; crmType?: number },
  { rejectValue: void }
>('getClientsList', async ({ page, limit, crmType }) => {
  const response = await axiosInstance.get<void, AxiosResponse<{ items: Client[]; total: number }>>(`/client`, {
    params: {
      page,
      limit,
      crmType,
    },
  })
  return response.data
})

export const getClientsListSelect = createAsyncThunk<{ items: Client[] }, void, { rejectValue: void }>(
  'getClientsListSelect',
  async () => {
    const response = await axiosInstance.get<void, AxiosResponse<{ items: Client[] }>>(`/client?page=1&limit=10000`)
    return response.data
  },
)

export const getClient = createAsyncThunk<Client, number, { rejectValue: void }>('getClient', async id => {
  const response = await axiosInstance.get<void, AxiosResponse<Client>>(`/client/${id}`)
  return response.data
})

export const createClient = createAsyncThunk<Client, ClientData, { rejectValue: void }>(
  'createClient',
  async client => {
    const response = await axiosInstance.post<void, AxiosResponse<Client>>(`/client`, client)
    return response.data
  },
)

export const updateClient = createAsyncThunk<Client, Client, { rejectValue: void }>('updateClient', async client => {
  const response = await axiosInstance.put<void, AxiosResponse<Client>>(`/client/${client.id}`, client)
  return response.data
})
export const deleteClient = createAsyncThunk<boolean, number, { rejectValue: void }>(
  'deleteClient',
  async (id, { dispatch }) => {
    const response = await axiosInstance.delete<void, AxiosResponse<boolean>>(`/client/${id}`)
    dispatch(checkSelectedClient(id))
    return response.data
  },
)

export const clientSlice = createSlice({
  name: 'client',
  initialState: clientState,
  reducers: {
    resetClient(state) {
      state.selectedClient = null
    },
    checkSelectedClient(state, action) {
      if (state.selectedClient?.id === action.payload) {
        state.selectedClient = null
      }
    },
  },
  extraReducers: builder => {
    builder.addCase(getClientsList.pending, state => {
      state.loading = true
      state.error = null
    })
    builder.addCase(getClientsList.rejected, (state, action) => {
      state.error = action.error.message ? action.error.message : 'Unknown error'
      state.loading = false
      state.clients = []
      state.total = 0
    })
    builder.addCase(getClientsList.fulfilled, (state, action) => {
      state.error = null
      state.loading = false
      state.clients = action.payload.items
      state.total = action.payload.total
    })

    builder.addCase(getClientsListSelect.pending, state => {
      state.loading = true
      state.error = null
    })
    builder.addCase(getClientsListSelect.rejected, (state, action) => {
      state.error = action.error.message ? action.error.message : 'Unknown error'
      state.loading = false
      state.clientsSelect = []
    })
    builder.addCase(getClientsListSelect.fulfilled, (state, action) => {
      state.error = null
      state.loading = false
      state.clientsSelect = action.payload.items
    })

    builder.addCase(getClient.pending, state => {
      state.loading = true
      state.error = null
    })
    builder.addCase(getClient.rejected, (state, action) => {
      state.error = action.error.message ? action.error.message : 'Unknown error'
      state.loading = false
      state.selectedClient = null
    })
    builder.addCase(getClient.fulfilled, (state, action) => {
      state.error = null
      state.loading = false
      state.selectedClient = action.payload
    })

    builder.addCase(createClient.pending, state => {
      state.loading = true
      state.error = null
    })
    builder.addCase(createClient.rejected, (state, action) => {
      state.error = action.error.message ? action.error.message : 'Unknown error'
      state.loading = false
      state.selectedClient = null
    })
    builder.addCase(createClient.fulfilled, (state, action) => {
      state.error = null
      state.loading = false
      state.selectedClient = action.payload
    })

    builder.addCase(updateClient.pending, state => {
      state.loading = true
      state.error = null
    })
    builder.addCase(updateClient.rejected, (state, action) => {
      state.error = action.error.message ? action.error.message : 'Unknown error'
      state.loading = false
    })
    builder.addCase(updateClient.fulfilled, (state, action) => {
      state.error = null
      state.loading = false
      state.selectedClient = action.payload
    })

    builder.addCase(deleteClient.pending, state => {
      state.loading = true
      state.error = null
    })
    builder.addCase(deleteClient.rejected, (state, action) => {
      state.error = action.error.message ? action.error.message : 'Unknown error'
      state.loading = false
    })
    builder.addCase(deleteClient.fulfilled, (state, action) => {
      state.error = null
      state.loading = false
    })
  },
})
export const { resetClient, checkSelectedClient } = clientSlice.actions
export default clientSlice.reducer
