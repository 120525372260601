import { Col, Form, FormInstance, InputNumber, Popconfirm, Row, Select } from 'antd'
import React, { useEffect, useState } from 'react'
import { RebillCycleAttempt } from 'features/productRebills/types'
import { DeleteFilled } from '@ant-design/icons'
import { timeUnit } from 'untils/enums'

const ProductRebillCycleAttempt = ({
  attempt,
  onDelete,
  allowDelete,
  form,
}: {
  attempt: RebillCycleAttempt
  onDelete: (attempt: RebillCycleAttempt) => void
  allowDelete: boolean
  form: FormInstance
}) => {
  const [minDelay, setMinDelay] = useState(1)
  const [maxDelay, setMaxDelay] = useState(1)
  const [delayTimeUnit, setDelayTimeUnit] = useState<'min' | 'hour' | 'day'>('min')
  useEffect(() => {
    if (attempt) {
      let tpmMinDelay = attempt.delayMin
      let tpmMaxDelay = attempt.delayMax
      if (tpmMinDelay % 60 === 0) {
        tpmMinDelay = tpmMinDelay / 60
        tpmMaxDelay = tpmMaxDelay / 60
        setDelayTimeUnit('hour')
      }
      if (tpmMinDelay % 24 === 0) {
        tpmMinDelay = tpmMinDelay / 24
        tpmMaxDelay = tpmMaxDelay / 24
        setDelayTimeUnit('day')
      }
      setMinDelay(tpmMinDelay)
      setMaxDelay(tpmMaxDelay)
    }
  }, [])

  /* useEffect(() => {
    if (attempt) {
      attempt.delayMin = minDelay
      attempt.delayMax = maxDelay
      if (delayTimeUnit === 'hour') {
        attempt.delayMin = minDelay * 60
        attempt.delayMax = maxDelay * 60
      }
      if (delayTimeUnit === 'day') {
        attempt.delayMin = minDelay * 60 * 24
        attempt.delayMax = maxDelay * 60 * 24
      }
    }
  }, [delayTimeUnit])*/

  const updateTimeUnit = (value: 'min' | 'hour' | 'day') => {
    setDelayTimeUnit(value)
    if (attempt) {
      attempt.delayMin = minDelay
      attempt.delayMax = maxDelay
      if (value === 'hour') {
        attempt.delayMin = minDelay * 60
        attempt.delayMax = maxDelay * 60
      }
      if (value === 'day') {
        attempt.delayMin = minDelay * 60 * 24
        attempt.delayMax = maxDelay * 60 * 24
      }
    }
  }
  return (
    <Row key={attempt.step} className={'productAttempt'} justify={'start'} align={'middle'} wrap={false}>
      <span className={'productAttempt__step'}>{attempt.step + 1}</span>
      <Row>
        <Row gutter={8} style={{ width: '100%' }}>
          <Col sm={12} xs={24}>
            <Form.Item
              label={'Price'}
              name={attempt.key + 'price'}
              rules={[{ required: true, message: 'Please Enter Price' }]}
              initialValue={attempt?.price}
            >
              <InputNumber
                onChange={value => {
                  if (value) {
                    attempt.price = value
                  }
                }}
                placeholder={'Price'}
                min={0}
                step={0.01}
                precision={2}
              />
            </Form.Item>
          </Col>
          <Col sm={12} xs={24}>
            <Form.Item
              label={'Quantity'}
              name={attempt.key + 'quantity'}
              rules={[{ required: true, message: 'Please Enter Quantity' }]}
              initialValue={attempt?.quantity ? attempt.quantity : 1}
            >
              <InputNumber
                onChange={value => {
                  if (value) {
                    attempt.quantity = value
                  }
                }}
                placeholder={'Quantity'}
                min={1}
                step={1}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={8} style={{ width: '100%' }}>
          <Col xs={12}>
            <Form.Item
              label={`Min Delay (in ${timeUnit[delayTimeUnit]})`}
              name={attempt.key + 'delayMin'}
              rules={[{ required: true, message: 'Please Enter Min Delay' }]}
              initialValue={attempt.delayMin}
            >
              <Row wrap={false}>
                <InputNumber
                  style={{ flex: 2 }}
                  placeholder={'Min Delay'}
                  min={1}
                  value={minDelay}
                  onChange={value => {
                    if (value) {
                      setMinDelay(value)
                      attempt.delayMin = value
                      if (delayTimeUnit === 'hour') {
                        attempt.delayMin = value * 60
                      }
                      if (delayTimeUnit === 'day') {
                        attempt.delayMin = value * 60 * 24
                      }
                    }
                  }}
                  onBlur={() => {
                    if (minDelay > maxDelay) {
                      setMaxDelay(minDelay)
                      form.setFieldValue(attempt.key + 'delayMax', minDelay)
                      attempt.delayMax = minDelay
                      if (delayTimeUnit === 'hour') {
                        attempt.delayMax = minDelay * 60
                      }
                      if (delayTimeUnit === 'day') {
                        attempt.delayMax = minDelay * 60 * 24
                      }
                    }
                  }}
                />
                <Select
                  style={{ flex: 1 }}
                  value={delayTimeUnit}
                  options={[
                    { value: 'min', label: 'Min' },
                    { value: 'hour', label: 'Hour' },
                    { value: 'day', label: 'Day' },
                  ]}
                  onChange={updateTimeUnit}
                />
              </Row>
            </Form.Item>
          </Col>
          <Col xs={12}>
            <Form.Item
              label={`Max Delay (in ${timeUnit[delayTimeUnit]})`}
              name={attempt.key + 'delayMax'}
              rules={[{ required: true, message: 'Please Enter Max Delay' }]}
              initialValue={attempt.delayMax}
            >
              <Row wrap={false}>
                <InputNumber
                  style={{ flex: 2 }}
                  placeholder={'Max Delay'}
                  min={1}
                  value={maxDelay}
                  onChange={value => {
                    if (value) {
                      setMaxDelay(value)
                      attempt.delayMax = value
                      if (delayTimeUnit === 'hour') {
                        attempt.delayMax = value * 60
                      }
                      if (delayTimeUnit === 'day') {
                        attempt.delayMax = value * 60 * 24
                      }
                    }
                  }}
                  onBlur={() => {
                    if (minDelay > maxDelay) {
                      setMinDelay(maxDelay)
                      form.setFieldValue(attempt.key + 'delayMin', maxDelay)
                      attempt.delayMin = maxDelay
                      if (delayTimeUnit === 'hour') {
                        attempt.delayMin = maxDelay * 60
                      }
                      if (delayTimeUnit === 'day') {
                        attempt.delayMin = maxDelay * 60 * 24
                      }
                    }
                  }}
                />
                <Select
                  style={{ flex: 1 }}
                  value={delayTimeUnit}
                  options={[
                    { value: 'min', label: 'Min' },
                    { value: 'hour', label: 'Hour' },
                    { value: 'day', label: 'Day' },
                  ]}
                  onChange={updateTimeUnit}
                />
              </Row>
            </Form.Item>
          </Col>
        </Row>
      </Row>

      {allowDelete && (
        <div className={'productAttempt__actions actions'}>
          <Popconfirm
            title='Delete Attempt'
            description='Confirm Attempt remove'
            onConfirm={() => onDelete(attempt)}
            okText='Confirm'
            cancelText='Cancel'
          >
            <DeleteFilled />
          </Popconfirm>
        </div>
      )}
    </Row>
  )
}
export default ProductRebillCycleAttempt
