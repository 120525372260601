import { useAppDispatch, useAppSelector } from 'hooks/redux'
import React, { useEffect, useState } from 'react'
import { resetOffer } from 'features/offer/offerSlice'
import OfferForm from 'features/offer/components/offerForm'
import { Button, Row } from 'antd'
import { Link } from 'react-router-dom'

const OfferCreatePage = () => {
  const dispatch = useAppDispatch()
  useEffect(() => {
    dispatch(resetOffer())
  }, [])

  return (
    <>
      <Row justify='space-between' align={'middle'}>
        <h3>Offer Create</h3>
        <Link to={'/offers'}>
          <Button>Back</Button>
        </Link>
      </Row>
      <OfferForm />
    </>
  )
}
export default OfferCreatePage
