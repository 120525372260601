import { useAppDispatch, useAppSelector } from 'hooks/redux'
import React, { useEffect, useState } from 'react'
import { resetAccount } from 'features/account/accountSlice'
import AccountForm from 'features/account/components/accountForm'
import { Button, Col, DatePicker, Form, Popconfirm, Row, Select, Spin, Table, TableColumnType, Tooltip } from 'antd'
import { Link, useParams } from 'react-router-dom'
import { getProductRebill, getProductRebills, resetProductRebill } from 'features/productRebills/productRebillsSlice'
import ProductRebillForm from 'features/productRebills/components/productRebillForm'
import { createProduct, getProduct, resetProduct } from 'features/product/productSlice'
import dayjs, { Dayjs } from 'dayjs'
import FormItem from 'antd/es/form/FormItem'
import { getOffersListSelect } from 'features/offer/offerSlice'
import { getReport, getReportRebills } from 'features/report/reportSlice'
import { Product } from 'features/product/types'
import { DeleteFilled } from '@ant-design/icons'
import { ReportData, ReportDataTable, ReportDataTableRebills } from 'features/report/types'
import { GroupByType } from 'untils/enums'
type reportFrom = {
  dateFrom: string
  dateTo: string
  offerId: number
  groupBy: 'affId' | 'bin'
}

const RebillsReport = () => {
  const { id } = useParams()
  const dispatch = useAppDispatch()
  const { offersSelect } = useAppSelector(state => state.offerReducer)
  const { reportRebills } = useAppSelector(state => state.reportReducer)
  const [form] = Form.useForm<reportFrom>()
  const [groupBy, setGroupBy] = useState<'affId' | 'bin'>('affId')
  const [tableData, setTableData] = useState<ReportDataTableRebills[]>([])
  const [dateFrom, setDateFrom] = useState<Dayjs>(dayjs())
  const [dateTo, setDateTo] = useState<Dayjs>(dayjs())
  useEffect(() => {
    dispatch(getOffersListSelect({}))
  }, [])
  useEffect(() => {
    if (reportRebills) {
      const tmpData: ReportDataTableRebills[] = []
      Object.keys(reportRebills).forEach(data => {
        tmpData.push({
          groupBy: data,
          addonsSuccess: reportRebills[data].addonsSuccess,
          addonsDeclined: reportRebills[data].addonsDeclined,
          addonsPlanned: reportRebills[data].addonsPlanned,
          attemptsSuccess: reportRebills[data].attemptsSuccess,
          attemptsDeclined: reportRebills[data].attemptsDeclined,
          attemptsPlanned: reportRebills[data].attemptsPlanned,
          rebillsSuccess: reportRebills[data].rebillsSuccess,
          rebillsDeclined: reportRebills[data].rebillsDeclined,
          rebillsPlanned: reportRebills[data].rebillsPlanned,
          rebillAttemptsPlanned: reportRebills[data].rebillAttemptsPlanned,
          rebillCancels: reportRebills[data].rebillCancels,
        })
      })
      setTableData(tmpData)
    }
  }, [reportRebills])

  const exportToCSV = () => {
    let totalAddonsSuccess = 0
    let totalAddonsDeclined = 0
    let totalAddonsPlanned = 0
    let totalAttemptsSuccess = 0
    let totalAttemptsDeclined = 0
    let totalAttemptsPlanned = 0
    let totalRebillsSuccess = 0
    let totalRebillsDeclined = 0
    let totalRebillAttemptsPlanned = 0
    let totalRebillsPlanned = 0
    let totalRebillCancels = 0
    let csv: string = columns.map(column => `"${column.title}"`).join(',') + '\r\n'
    tableData.forEach(data => {
      totalAddonsSuccess += data.addonsSuccess
      totalAddonsDeclined += data.addonsDeclined
      totalAddonsPlanned += data.addonsPlanned
      totalAttemptsSuccess += data.attemptsSuccess
      totalAttemptsDeclined += data.attemptsDeclined
      totalAttemptsPlanned += data.attemptsPlanned
      totalRebillsSuccess += data.rebillsSuccess
      totalRebillsDeclined += data.rebillsDeclined
      totalRebillsPlanned += data.rebillsPlanned
      totalRebillAttemptsPlanned += data.rebillAttemptsPlanned
      totalRebillCancels += data.rebillCancels
      let row = ''
      columns.forEach((column, index) => {
        if (index > 0) {
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-expect-error
          row += `,"${data[column.dataIndex]}"`
        } else {
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-expect-error
          row += `"${data[column.dataIndex]}"`
        }
      })
      csv += row + '\r\n'
    })
    csv += `"Total","${totalAddonsSuccess}","${totalAddonsDeclined}","${totalAddonsPlanned}","${totalAttemptsSuccess}","${totalAttemptsDeclined}","${totalAttemptsPlanned}","${totalRebillsSuccess}","${totalRebillsDeclined}","${totalRebillsPlanned}","${totalRebillAttemptsPlanned}","${totalRebillCancels}"`
    const blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' })
    const url = URL.createObjectURL(blob)

    // Create a link to download it
    const pom = document.createElement('a')
    pom.href = url
    pom.setAttribute('download', 'Rebills Report.csv')
    pom.click()
  }

  const generateReport = (values: reportFrom) => {
    dispatch(
      getReportRebills({
        groupBy: values.groupBy,
        offerId: values.offerId,
        dateFrom: dayjs(values.dateFrom).format('YYYY.MM.DD'),
        dateTo: dayjs(values.dateTo).format('YYYY.MM.DD'),
      }),
    )
    setGroupBy(values.groupBy)
  }

  const columns: TableColumnType<ReportDataTableRebills>[] = [
    {
      title: GroupByType[groupBy],
      dataIndex: 'groupBy',
      key: 'groupBy',
      fixed: 'left',
    },
    {
      title: 'Addons S',
      dataIndex: 'addonsSuccess',
      key: 'addonsSuccess',
    },
    {
      title: 'Addons D',
      dataIndex: 'addonsDeclined',
      key: 'addonsDeclined',
    },
    {
      title: 'Addons P',
      dataIndex: 'addonsPlanned',
      key: 'addonsPlanned',
    },
    {
      title: 'Attempts S',
      dataIndex: 'attemptsSuccess',
      key: 'attemptsSuccess',
    },
    {
      title: 'Attempts D',
      dataIndex: 'attemptsDeclined',
      key: 'attemptsDeclined',
    },
    {
      title: 'Attempts P',
      dataIndex: 'attemptsPlanned',
      key: 'attemptsPlanned',
    },
    {
      title: 'Rebills S',
      dataIndex: 'rebillsSuccess',
      key: 'rebillsSuccess',
    },
    {
      title: 'Rebills D',
      dataIndex: 'rebillsDeclined',
      key: 'rebillsDeclined',
    },
    {
      title: 'rebills P',
      dataIndex: 'rebillsPlanned',
      key: 'rebillsPlanned',
    },
    {
      title: 'Rebill Reattempt',
      dataIndex: 'rebillAttemptsPlanned',
      key: 'rebillAttemptsPlanned',
    },
    {
      title: 'Rebill Cancellations',
      dataIndex: 'rebillCancels',
      key: 'rebillCancels',
    },
  ]

  return (
    <>
      <Row justify='space-between' align={'middle'}>
        <h3>Rebills Report</h3>
        <Form onFinish={generateReport} form={form} style={{ width: '100%' }}>
          <Row style={{ width: '100%' }}>
            <Col md={9} lg={7} xl={5}>
              <FormItem labelCol={{ span: 8 }} label={'Date From'} name={'dateFrom'} initialValue={dayjs()}>
                <DatePicker
                  allowClear={false}
                  onChange={value => {
                    if (value) setDateFrom(value)
                  }}
                  disabledDate={date => date.isAfter(dateTo.add(1, 'day').format('YYYY.MM.DD'))}
                />
              </FormItem>
            </Col>
            <Col md={9} lg={7} xl={5}>
              <FormItem labelCol={{ span: 8 }} label={'Offer'} name={'offerId'} initialValue={null}>
                <Select
                  options={[
                    { label: 'All', value: null },
                    ...offersSelect.map(offer => ({
                      label: offer.name,
                      value: offer.id,
                    })),
                  ]}
                />
              </FormItem>
            </Col>
          </Row>
          <Row style={{ width: '100%' }}>
            <Col md={9} lg={7} xl={5}>
              <FormItem labelCol={{ span: 8 }} label={'Date To'} name={'dateTo'} initialValue={dayjs()}>
                <DatePicker
                  allowClear={false}
                  disabledDate={date => date.isBefore(dateFrom.format('YYYY.MM.DD'))}
                  onChange={value => {
                    if (value) setDateTo(value)
                  }}
                />
              </FormItem>
            </Col>

            <Col md={9} lg={7} xl={5}>
              <FormItem labelCol={{ span: 8 }} label={'Group By'} name={'groupBy'} initialValue={'affId'}>
                <Select
                  options={[
                    { label: GroupByType.affId, value: 'affId' },
                    { label: GroupByType.bin, value: 'bin' },
                    { label: GroupByType.date, value: 'date' },
                  ]}
                />
              </FormItem>
            </Col>
          </Row>
          <Row gutter={16} style={{ width: '100%' }}>
            <Col>
              <Button type={'primary'} htmlType={'submit'}>
                Generate
              </Button>
            </Col>
            {reportRebills && (
              <Col>
                <Button onClick={exportToCSV}>Export to CSV</Button>
              </Col>
            )}
          </Row>
        </Form>
      </Row>

      {reportRebills && (
        <Table
          columns={columns}
          pagination={false}
          scroll={{ x: 'max-content' }}
          dataSource={tableData}
          summary={pageData => {
            let totalAddonsSuccess = 0
            let totalAddonsDeclined = 0
            let totalAddonsPlanned = 0
            let totalAttemptsSuccess = 0
            let totalAttemptsDeclined = 0
            let totalAttemptsPlanned = 0
            let totalRebillsSuccess = 0
            let totalRebillsDeclined = 0
            let totalRebillAttemptsPlanned = 0
            let totalRebillsPlanned = 0
            let totalRebillCancels = 0
            if (!pageData.length) return null
            pageData.forEach(
              ({
                addonsSuccess,
                addonsDeclined,
                addonsPlanned,
                attemptsSuccess,
                attemptsDeclined,
                attemptsPlanned,
                rebillsSuccess,
                rebillsDeclined,
                rebillsPlanned,
                rebillAttemptsPlanned,
                rebillCancels,
              }) => {
                totalAddonsSuccess += addonsSuccess
                totalAddonsDeclined += addonsDeclined
                totalAddonsPlanned += addonsPlanned
                totalAttemptsSuccess += attemptsSuccess
                totalAttemptsDeclined += attemptsDeclined
                totalAttemptsPlanned += attemptsPlanned
                totalRebillsSuccess += rebillsSuccess
                totalRebillsDeclined += rebillsDeclined
                totalRebillsPlanned += rebillsPlanned
                totalRebillAttemptsPlanned += rebillAttemptsPlanned
                totalRebillCancels += rebillCancels
              },
            )
            return (
              <>
                <Table.Summary.Row>
                  <Table.Summary.Cell index={0}>
                    <b>Total</b>
                  </Table.Summary.Cell>
                  <Table.Summary.Cell index={1}>
                    <b>{totalAddonsSuccess}</b>
                  </Table.Summary.Cell>
                  <Table.Summary.Cell index={2}>
                    <b>{totalAddonsDeclined}</b>
                  </Table.Summary.Cell>
                  <Table.Summary.Cell index={3}>
                    <b>{totalAddonsPlanned}</b>
                  </Table.Summary.Cell>
                  <Table.Summary.Cell index={4}>
                    <b>{totalAttemptsSuccess}</b>
                  </Table.Summary.Cell>
                  <Table.Summary.Cell index={5}>
                    <b>{totalAttemptsDeclined}</b>
                  </Table.Summary.Cell>
                  <Table.Summary.Cell index={6}>
                    <b>{totalAttemptsPlanned}</b>
                  </Table.Summary.Cell>
                  <Table.Summary.Cell index={7}>
                    <b>{totalRebillsSuccess}</b>
                  </Table.Summary.Cell>
                  <Table.Summary.Cell index={8}>
                    <b>{totalRebillsDeclined}</b>
                  </Table.Summary.Cell>
                  <Table.Summary.Cell index={9}>
                    <b>{totalRebillsPlanned}</b>
                  </Table.Summary.Cell>
                  <Table.Summary.Cell index={10}>
                    <b>{totalRebillAttemptsPlanned}</b>
                  </Table.Summary.Cell>
                  <Table.Summary.Cell index={11}>
                    <b>{totalRebillCancels}</b>
                  </Table.Summary.Cell>
                </Table.Summary.Row>
              </>
            )
          }}
        />
      )}
    </>
  )
}
export default RebillsReport
