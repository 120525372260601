import { useAppDispatch, useAppSelector } from 'hooks/redux'
import { Button, Col, Popconfirm, Row, Spin, Tooltip } from 'antd'
import { Link, useNavigate, useParams } from 'react-router-dom'
import React, { useEffect } from 'react'
import {
  deleteProductAddon,
  getProductAddon,
  getProductAddons,
  resetProductAddon,
  updateProductAddonStatus,
} from 'features/productAddons/productAddonsSlice'

import { addNotification } from 'features/notifications/notificationHandler'
import { getProduct, getProductsListSelect } from 'features/product/productSlice'
import ProductAddonModal from 'features/productAddons/components/productAddonModal'
import { DeleteFilled, EditFilled, FieldTimeOutlined, PauseCircleFilled, PlayCircleOutlined } from '@ant-design/icons'
import { timeUnit } from 'untils/enums'

const ProductAddonsList = () => {
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const [open, setOpen] = React.useState(false)
  const { error, loading, productAddons } = useAppSelector(state => state.productAddonReducer)
  const { selectedProduct, error: productError, productsSelect } = useAppSelector(state => state.productReducer)
  const { id } = useParams()

  useEffect(() => {
    if (id && +id > 0) {
      dispatch(getProduct(+id))
    }
  }, [id])

  useEffect(() => {
    if (selectedProduct !== null) {
      dispatch(getProductAddons(selectedProduct.id))
      dispatch(getProductsListSelect(selectedProduct.accountId))
    }
  }, [selectedProduct])

  useEffect(() => {
    if (error) {
      dispatch(
        addNotification({
          type: 'error',
          message: error,
        }),
      )
    }
    if (productError) {
      dispatch(
        addNotification({
          type: 'error',
          message: productError,
        }),
      )
      navigate('/products')
    }
  }, [error, productError])

  const toggle = () => {
    setOpen(!open)
  }

  const onEdit = (id: number) => {
    if (selectedProduct !== null) {
      dispatch(getProductAddon(id)).then(response => {
        if (response.payload) {
          toggle()
        }
      })
    }
  }
  const onDelete = (id: number) => {
    if (selectedProduct !== null) {
      dispatch(deleteProductAddon(id)).then(response => {
        if (response.payload) {
          dispatch(
            addNotification({
              type: 'success',
              message: 'Product Addon removed successfully',
            }),
          )
          dispatch(getProductAddons(selectedProduct.id))
        }
      })
    }
  }

  const onUpdateStatus = (id: number, status: boolean) => {
    if (selectedProduct !== null) {
      dispatch(updateProductAddonStatus({ id, active: status })).then(response => {
        if (response.payload) {
          dispatch(
            addNotification({
              type: 'success',
              message: status ? 'Activated successfully' : 'Paused successfully',
            }),
          )
          dispatch(getProductAddons(selectedProduct.id))
        }
      })
    }
  }

  const createProductAddon = () => {
    dispatch(resetProductAddon())
    toggle()
  }
  if (!selectedProduct || !productsSelect?.length) return null
  return (
    <>
      <Row justify='space-between' align={'middle'}>
        <h3>
          Product Addons ({selectedProduct.name} {selectedProduct.externalId})
        </h3>
        <Row gutter={8}>
          <Col>
            <Link to={'/products'}>
              <Button>Back</Button>
            </Link>
          </Col>
          <Col>
            <Button onClick={createProductAddon} type={'primary'}>
              Create Addon
            </Button>
          </Col>
        </Row>
      </Row>
      <ProductAddonModal open={open} toggle={toggle} />
      {loading && <Spin />}
      {!loading &&
        selectedProduct.id &&
        productAddons.map(addon => {
          const findProduct = productsSelect.find(product => product.id === addon.addonProductId)
          let productName = ''
          if (findProduct) {
            productName = `${findProduct.name} (${findProduct.externalId})`
          }
          let minDelay = addon.delayMin
          let maxDelay = addon.delayMax
          let delayTimeUnit: 'min' | 'hour' | 'day' = 'min'
          if (minDelay % 60 === 0) {
            minDelay = minDelay / 60
            maxDelay = maxDelay / 60
            delayTimeUnit = 'hour'
          }
          if (minDelay % 24 === 0) {
            minDelay = minDelay / 24
            maxDelay = maxDelay / 24
            delayTimeUnit = 'day'
          }

          return (
            <div key={addon.id} className={'productAddon'}>
              <h4 className={'productAddon__productName'}>{productName}</h4>
              <Row justify={'start'} align={'middle'}>
                <div>
                  <div>
                    <FieldTimeOutlined className={'delay'} /> Min Delay: {minDelay} {timeUnit[delayTimeUnit]}
                  </div>
                  <div>
                    <FieldTimeOutlined className={'delay'} /> Max Delay: {maxDelay} {timeUnit[delayTimeUnit]}
                  </div>
                </div>

                <div className={'productAddon__price'}>
                  <span className={'currency'}>$</span>
                  {Number(addon.price) !== 0 ? addon.price : 'Auto'}
                </div>
                <div className={'productAddon__quantity'}>
                  <span>Qty:</span> {addon.quantity}
                </div>

                <div className={'productAddon__actions actions'}>
                  {!addon.active && (
                    <Tooltip title={'Activate'} placement={'top'}>
                      <PlayCircleOutlined onClick={() => onUpdateStatus(addon.id, true)} />
                    </Tooltip>
                  )}
                  {addon.active && (
                    <Tooltip title={'Disable'} placement={'top'}>
                      <PauseCircleFilled onClick={() => onUpdateStatus(addon.id, false)} />
                    </Tooltip>
                  )}
                  <Tooltip title={'Edit'} placement={'top'}>
                    <EditFilled onClick={() => onEdit(addon.id)} />
                  </Tooltip>
                  <Tooltip title={'Delete'} placement={'top'}>
                    <Popconfirm
                      title='Delete Product Addon'
                      description='Confirm Product Addon remove'
                      onConfirm={() => onDelete(addon.id)}
                      okText='Confirm'
                      cancelText='Cancel'
                    >
                      <DeleteFilled />
                    </Popconfirm>
                  </Tooltip>
                </div>
              </Row>
            </div>
          )
        })}
    </>
  )
}
export default ProductAddonsList
