import { useAppDispatch, useAppSelector } from 'hooks/redux'
import { Button, Col, Popconfirm, Row, Select, Spin, Table, Tooltip } from 'antd'
import { Link, useNavigate } from 'react-router-dom'
import React, { useEffect, useState } from 'react'
import {
  createLoadBalancerRule,
  deleteLoadBalancerRule,
  getLoadBalancerRules,
  resetLoadBalancerRule,
} from 'features/loadBalancer/loadBalancerSlice'
import { LoadBalancerRule } from 'features/loadBalancer/types'
import { DeleteFilled, EditFilled, FieldTimeOutlined, PauseCircleFilled, PlayCircleOutlined } from '@ant-design/icons'
import { getAccountsList, getAccountsListSelect, resetAccount } from 'features/account/accountSlice'
import { CRMType } from 'untils/enums'
import { addNotification } from 'features/notifications/notificationHandler'
import Cookies from 'js-cookie'
import { removeFromCookies, saveToCookies } from 'untils/saveToCookies'
import { getProductsListSelect } from 'features/product/productSlice'
import { getMidsListSelect } from 'features/mid/midSlice'
import {
  deleteProductAddon,
  getProductAddon,
  getProductAddons,
  updateProductAddonStatus,
} from 'features/productAddons/productAddonsSlice'

const LoadBalancerRulesList = () => {
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const { error, loading, loadBalancerRules, total } = useAppSelector(state => state.loadBalancerReducer)
  const { error: productsError, productsSelect } = useAppSelector(state => state.productReducer)
  const { error: midsError, midsSelect } = useAppSelector(state => state.midReducer)
  const {
    error: accountsError,
    loading: accountsLoading,
    accountsSelect,
  } = useAppSelector(state => state.accountReducer)

  const [accountId, setAccountId] = useState<null | number>(null)
  const [crmType, setCrmType] = useState(-1)

  useEffect(() => {
    const account = Cookies.get('activeAccountId')
    const crm = Cookies.get('crmType')
    if (account !== undefined && +account > 0) {
      setAccountId(+account)
    }
    if (crm !== undefined && +crm >= -1) {
      setCrmType(+crm)
    }
  }, [])

  useEffect(() => {
    if (accountId !== null) {
      dispatch(getLoadBalancerRules(accountId))
      dispatch(getProductsListSelect(accountId))
      dispatch(getMidsListSelect(accountId))
    }
  }, [accountId])
  useEffect(() => {
    if (crmType > -1) {
      dispatch(getAccountsListSelect({ crmType }))
    } else {
      dispatch(getAccountsListSelect({}))
    }
  }, [crmType])

  useEffect(() => {
    if (error) {
      dispatch(
        addNotification({
          type: 'error',
          message: error,
        }),
      )
    }
    if (accountsError) {
      dispatch(
        addNotification({
          type: 'error',
          message: accountsError,
        }),
      )
    }
    if (midsError) {
      dispatch(
        addNotification({
          type: 'error',
          message: midsError,
        }),
      )
    }
    if (productsError) {
      dispatch(
        addNotification({
          type: 'error',
          message: productsError,
        }),
      )
    }
  }, [error, accountsError, midsError, productsError])

  const createRule = () => {
    dispatch(resetLoadBalancerRule())
    navigate('/loadBalancerRule')
  }
  const onEdit = (id: number) => {
    if (accountId !== null) {
      navigate(`/loadBalancerRule/${id}`)
    }
  }
  const onDelete = (id: number) => {
    if (accountId !== null) {
      dispatch(deleteLoadBalancerRule(id)).then(response => {
        if (response.payload) {
          dispatch(
            addNotification({
              type: 'success',
              message: 'Load Balancer Rule removed successfully',
            }),
          )
          dispatch(getLoadBalancerRules(accountId))
        }
      })
    }
  }

  const onUpdateStatus = (id: number, status: boolean) => {
    if (accountId !== null) {
      dispatch(updateProductAddonStatus({ id, active: status })).then(response => {
        if (response.payload) {
          dispatch(
            addNotification({
              type: 'success',
              message: status ? 'Activated successfully' : 'Paused successfully',
            }),
          )
          dispatch(getLoadBalancerRules(accountId))
        }
      })
    }
  }

  return (
    <>
      <Row justify='space-between' align={'middle'}>
        <h3>Load Balancer Rules</h3>
        <Button onClick={createRule}>Create Rule</Button>
      </Row>
      <Row justify='start' gutter={8}>
        <Col md={6} sm={12} xs={24}>
          <Select
            style={{ width: '100%' }}
            placeholder={'Please Select CRM Type'}
            value={crmType}
            options={[
              { label: 'All', value: -1 },
              { label: 'Response', value: CRMType.response },
              { label: 'Konnektive', value: CRMType.konnektive },
              { label: 'Sticky', value: CRMType.sticky },
              { label: 'Sublytics', value: CRMType.sublytics },
            ]}
            onChange={(value, option) => {
              if (value !== -1) {
                setAccountId(null)
                removeFromCookies('activeAccountId')
              }
              setCrmType(value)
              saveToCookies('crmType', value.toString())
            }}
          />
        </Col>
        <Col md={6} sm={12} xs={24}>
          <Select
            loading={accountsLoading}
            style={{ width: '100%' }}
            placeholder={'Account'}
            value={accountId}
            options={accountsSelect.map(account => ({ label: account.name, value: account.id }))}
            onChange={(value, option) => {
              setAccountId(value)
              saveToCookies('crmType', value.toString())
            }}
          />
        </Col>
      </Row>
      {loading && <Spin />}
      {!loading &&
        accountId &&
        loadBalancerRules.map(rule => (
          <Row key={rule.id} className={'productAddon'} justify={'space-between'} align={'top'} wrap={false}>
            <Row align={'top'} style={{ flex: '2' }}>
              <Col sm={12} xs={24}>
                {rule.products.map(product => {
                  const findProduct = productsSelect.find(productS => productS.id === product.productId)
                  if (findProduct) {
                    return <div key={findProduct.id}>{`${findProduct.name} (${findProduct.externalId})`}</div>
                  }
                })}
              </Col>
              <Col sm={12} xs={24}>
                {rule.mids.map(mid => {
                  const findMid = midsSelect.find(midS => midS.id === mid.midId)
                  if (findMid) {
                    return <div key={findMid.id}>{`${mid.percent}% ${findMid.name} (${findMid.externalId})`}</div>
                  }
                })}
              </Col>
            </Row>

            <div className={'productAddon__actions actions'}>
              {!rule.active && (
                <Tooltip title={'Activate'} placement={'top'}>
                  <PlayCircleOutlined onClick={() => onUpdateStatus(rule.id, true)} />
                </Tooltip>
              )}
              {rule.active && (
                <Tooltip title={'Disable'} placement={'top'}>
                  <PauseCircleFilled onClick={() => onUpdateStatus(rule.id, false)} />
                </Tooltip>
              )}
              <Tooltip title={'Edit'} placement={'top'}>
                <EditFilled onClick={() => onEdit(rule.id)} />
              </Tooltip>
              <Tooltip title={'Delete'} placement={'top'}>
                <Popconfirm
                  title='Delete Product Addon'
                  description='Confirm Product Addon remove'
                  onConfirm={() => onDelete(rule.id)}
                  okText='Confirm'
                  cancelText='Cancel'
                >
                  <DeleteFilled />
                </Popconfirm>
              </Tooltip>
            </div>
          </Row>
        ))}{' '}
      {!accountId && !loading && <div className={'empty_data'}>Please select CRM Account</div>}
    </>
  )
}
export default LoadBalancerRulesList
