import { useAppDispatch, useAppSelector } from 'hooks/redux'
import React, { useEffect, useState } from 'react'
import { resetClient } from 'features/client/clientSlice'
import ClientForm from 'features/client/components/clientForm'
import { Link } from 'react-router-dom'
import { Button, Row } from 'antd'

const ClientCreatePage = () => {
  const dispatch = useAppDispatch()
  useEffect(() => {
    dispatch(resetClient())
  }, [])

  return (
    <>
      <Row justify='space-between' align={'middle'}>
        <h3>Client Create</h3>
        <Link to={'/clients'}>
          <Button>Back</Button>
        </Link>
      </Row>
      <ClientForm />
    </>
  )
}
export default ClientCreatePage
