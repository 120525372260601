import { useAppDispatch, useAppSelector } from 'hooks/redux'
import { Button, Col, Form, Input, InputNumber, Modal, Row, Select } from 'antd'
import { useNavigate } from 'react-router-dom'
import React, { useEffect, useState } from 'react'
import { ProductData } from 'features/product/types'
import { addNotification } from 'features/notifications/notificationHandler'
import { createProductAddon, getProductAddons, updateProductAddon } from 'features/productAddons/productAddonsSlice'
import { ProductAddonData } from 'features/productAddons/types'
import { timeUnit } from 'untils/enums'

const ProductAddonModal = ({ open, toggle }: { open: boolean; toggle: () => void }) => {
  const dispatch = useAppDispatch()
  const { selectedProduct, productsSelect } = useAppSelector(state => state.productReducer)
  const { error, loading, selectedProductAddon, productAddons } = useAppSelector(state => state.productAddonReducer)
  const [form] = Form.useForm()
  const [minDelay, setMinDelay] = useState(1)
  const [maxDelay, setMaxDelay] = useState(1)
  const [delayTimeUnit, setDelayTimeUnit] = useState<'min' | 'hour' | 'day'>('min')

  useEffect(() => {
    form.resetFields()
    if (selectedProductAddon?.id) {
      let tpmMinDelay = selectedProductAddon.delayMin
      let tpmMaxDelay = selectedProductAddon.delayMax
      if (tpmMinDelay % 60 === 0) {
        tpmMinDelay = tpmMinDelay / 60
        tpmMaxDelay = tpmMaxDelay / 60
        setDelayTimeUnit('hour')
      }
      if (tpmMinDelay % 24 === 0) {
        tpmMinDelay = tpmMinDelay / 24
        tpmMaxDelay = tpmMaxDelay / 24
        setDelayTimeUnit('day')
      }
      setMinDelay(tpmMinDelay)
      setMaxDelay(tpmMaxDelay)
    }
  }, [open])

  const formSubmit = async (values: ProductAddonData) => {
    if (!selectedProduct) {
      return
    }
    values.productId = selectedProduct.id
    values.productId = selectedProduct.id
    if (delayTimeUnit === 'day') {
      values.delayMin = values.delayMin * 60 * 24
      values.delayMax = values.delayMax * 60 * 24
    }
    if (delayTimeUnit === 'hour') {
      values.delayMin = values.delayMin * 60
      values.delayMax = values.delayMax * 60
    }
    if (selectedProductAddon?.id) {
      dispatch(updateProductAddon({ id: selectedProductAddon.id, ...values })).then(response => {
        if (response.payload) {
          dispatch(
            addNotification({
              type: 'success',
              message: 'Product updated successfully',
            }),
          )
          dispatch(getProductAddons(selectedProduct.id))
          toggle()
        }
      })
    } else {
      dispatch(createProductAddon(values)).then(response => {
        if (response.payload) {
          dispatch(
            addNotification({
              type: 'success',
              message: 'Product created successfully',
            }),
          )
          dispatch(getProductAddons(selectedProduct.id))
          toggle()
        }
      })
    }
  }
  if (!selectedProduct) return null
  return (
    <>
      <Modal
        open={open}
        onCancel={toggle}
        onOk={() => form.submit()}
        okText={selectedProductAddon?.id ? 'Update' : 'Create'}
        cancelText={'Cancel'}
        destroyOnClose={true}
        title={selectedProductAddon?.id ? 'Update Addon' : 'Create Addon'}
      >
        <Form form={form} onFinish={formSubmit} labelCol={{ span: 24, offset: 0 }} wrapperCol={{ span: 24, offset: 0 }}>
          <Row gutter={8}>
            <Col xs={24}>
              <Form.Item
                label={'Addon Product'}
                name={'addonProductId'}
                rules={[{ required: true, message: 'Please Select Product' }]}
                initialValue={selectedProductAddon?.addonProductId}
              >
                <Select
                  showSearch={true}
                  filterOption={(input, option) => (option?.label ?? '').toLowerCase().includes(input.toLowerCase())}
                  options={productsSelect
                    .filter(product => product.id !== selectedProduct.id)
                    .map(product => ({
                      label: `${product.name} (${product.externalId})`,
                      value: product.id,
                    }))}
                />
              </Form.Item>
            </Col>
            <Col sm={12} xs={24}>
              <Form.Item
                label={'Price'}
                name={'price'}
                rules={[{ required: true, message: 'Please Enter Price' }]}
                initialValue={selectedProductAddon?.price}
              >
                <InputNumber placeholder={'Price'} min={0} step={0.01} precision={2} />
              </Form.Item>
            </Col>
            <Col sm={12} xs={24}>
              <Form.Item
                label={'Quantity'}
                name={'quantity'}
                rules={[{ required: true, message: 'Please Enter Quantity' }]}
                initialValue={selectedProductAddon?.quantity ? selectedProductAddon.quantity : 1}
              >
                <InputNumber placeholder={'Quantity'} min={1} step={1} />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={8}>
            <Col xs={12}>
              <Form.Item
                label={`Min Delay (in ${timeUnit[delayTimeUnit]})`}
                name={'delayMin'}
                rules={[{ required: true, message: 'Please Enter Min Delay' }]}
                initialValue={selectedProductAddon?.delayMin}
              >
                <Row wrap={false}>
                  <InputNumber
                    style={{ flex: 2 }}
                    placeholder={'Min Delay'}
                    min={1}
                    value={minDelay}
                    onChange={value => {
                      if (value) {
                        setMinDelay(value)
                      }
                    }}
                    onBlur={() => {
                      if (minDelay > maxDelay) {
                        setMaxDelay(minDelay)
                        form.setFieldValue('delayMax', minDelay)
                      }
                    }}
                  />
                  <Select
                    style={{ flex: 1 }}
                    value={delayTimeUnit}
                    options={[
                      { value: 'min', label: 'Min' },
                      { value: 'hour', label: 'Hour' },
                      { value: 'day', label: 'Day' },
                    ]}
                    onChange={value => {
                      setDelayTimeUnit(value)
                    }}
                  />
                </Row>
              </Form.Item>
            </Col>
            <Col xs={12}>
              <Form.Item
                label={`Max Delay (in ${timeUnit[delayTimeUnit]})`}
                name={'delayMax'}
                rules={[{ required: true, message: 'Please Enter Max Delay' }]}
                initialValue={selectedProductAddon?.delayMax}
              >
                <Row wrap={false}>
                  <InputNumber
                    style={{ flex: 2 }}
                    placeholder={'Max Delay'}
                    min={1}
                    value={maxDelay}
                    onChange={value => {
                      if (value) {
                        setMaxDelay(value)
                      }
                    }}
                    onBlur={() => {
                      if (minDelay > maxDelay) {
                        setMinDelay(maxDelay)
                        form.setFieldValue('delayMin', maxDelay)
                      }
                    }}
                  />
                  <Select
                    style={{ flex: 1 }}
                    value={delayTimeUnit}
                    options={[
                      { value: 'min', label: 'Min' },
                      { value: 'hour', label: 'Hour' },
                      { value: 'day', label: 'Day' },
                    ]}
                    onChange={value => {
                      setDelayTimeUnit(value)
                    }}
                  />
                </Row>
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Modal>
    </>
  )
}
export default ProductAddonModal
