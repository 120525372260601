import { useAppDispatch, useAppSelector } from 'hooks/redux'
import React, { useEffect, useState } from 'react'
import { resetAccount } from 'features/account/accountSlice'
import AccountForm from 'features/account/components/accountForm'
import { Button, Col, Form, Row, Spin } from 'antd'
import { Link, useParams } from 'react-router-dom'
import { getProductRebill, getProductRebills, resetProductRebill } from 'features/productRebills/productRebillsSlice'
import ProductRebillForm from 'features/productRebills/components/productRebillForm'
import { createProduct, getProduct, resetProduct } from 'features/product/productSlice'

const ProductRebillPage = () => {
  const { id } = useParams()
  const dispatch = useAppDispatch()
  const { selectedProduct } = useAppSelector(state => state.productReducer)
  const [form] = Form.useForm()
  useEffect(() => {
    dispatch(resetProductRebill())
    dispatch(resetProduct())
  }, [])
  useEffect(() => {
    if (id && +id > 0) {
      dispatch(getProduct(+id))
      dispatch(getProductRebill(+id))
    }
  }, [id])

  if (!selectedProduct) return <Spin />
  return (
    <>
      <Row justify='space-between' align={'middle'}>
        <h3>
          Product Rebill ({selectedProduct?.name} {selectedProduct?.externalId})
        </h3>
        <Row gutter={8}>
          <Col>
            <Button disabled={!selectedProduct} type={'primary'} onClick={() => form.submit()}>
              Save
            </Button>
          </Col>
          <Col>
            <Link to={'/products'}>
              <Button>Back</Button>
            </Link>
          </Col>
        </Row>
      </Row>

      {selectedProduct && <ProductRebillForm form={form} />}
    </>
  )
}
export default ProductRebillPage
