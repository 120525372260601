import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import axiosInstance from 'untils'
import { AxiosResponse } from 'axios'
import { ProductAddon, ProductAddonData, ProductAddonState } from 'features/productAddons/types'
const productAddonState: ProductAddonState = {
  selectedProductAddon: null,
  productAddons: [],
  total: 0,
  error: null,
  loading: false,
}
export const getProductAddons = createAsyncThunk<
  { items: ProductAddon[]; total: number },
  number,
  { rejectValue: void }
>('getProductAddonsList', async productId => {
  const response = await axiosInstance.get<void, AxiosResponse<{ items: ProductAddon[]; total: number }>>(
    `/addonProduct`,
    {
      params: {
        productId,
      },
    },
  )
  return response.data
})

export const getProductAddon = createAsyncThunk<ProductAddon, number, { rejectValue: void }>(
  'getProductAddon',
  async id => {
    const response = await axiosInstance.get<void, AxiosResponse<ProductAddon>>(`/addonProduct/${id}`)
    return response.data
  },
)

export const createProductAddon = createAsyncThunk<ProductAddon, ProductAddonData, { rejectValue: void }>(
  'createProductAddon',
  async productAddon => {
    const response = await axiosInstance.post<void, AxiosResponse<ProductAddon>>(`/addonProduct`, productAddon)
    return response.data
  },
)

export const updateProductAddon = createAsyncThunk<ProductAddon, ProductAddon, { rejectValue: void }>(
  'updateProductAddon',
  async productAddon => {
    const response = await axiosInstance.put<void, AxiosResponse<ProductAddon>>(
      `/addonProduct/${productAddon.id}`,
      productAddon,
    )
    return response.data
  },
)
export const updateProductAddonStatus = createAsyncThunk<
  ProductAddon,
  { id: number; active: boolean },
  { rejectValue: void }
>('updateProductAddonStatus', async ({ id, active }) => {
  const response = await axiosInstance.put<void, AxiosResponse<ProductAddon>>(`/addonProduct/${id}/statusUpdate`, {
    active,
  })
  return response.data
})

export const deleteProductAddon = createAsyncThunk<boolean, number, { rejectValue: void }>(
  'deleteProductAddon',
  async (id, { dispatch }) => {
    const response = await axiosInstance.delete<void, AxiosResponse<boolean>>(`/addonProduct/${id}`)
    dispatch(checkSelectedProductAddon(id))
    return response.data
  },
)

export const productAddonAddonsSlice = createSlice({
  name: 'productAddon',
  initialState: productAddonState,
  reducers: {
    resetProductAddon(state) {
      state.selectedProductAddon = null
    },
    checkSelectedProductAddon(state, action) {
      if (state.selectedProductAddon?.id === action.payload) {
        state.selectedProductAddon = null
      }
    },
  },
  extraReducers: builder => {
    builder.addCase(getProductAddons.pending, state => {
      state.loading = true
      state.error = null
    })
    builder.addCase(getProductAddons.rejected, (state, action) => {
      state.error = action.error.message ? action.error.message : 'Unknown error'
      state.loading = false
      state.productAddons = []
    })
    builder.addCase(getProductAddons.fulfilled, (state, action) => {
      state.error = null
      state.loading = false
      state.productAddons = action.payload.items
    })

    builder.addCase(getProductAddon.pending, state => {
      state.loading = true
      state.error = null
    })
    builder.addCase(getProductAddon.rejected, (state, action) => {
      state.error = action.error.message ? action.error.message : 'Unknown error'
      state.loading = false
      state.selectedProductAddon = null
    })
    builder.addCase(getProductAddon.fulfilled, (state, action) => {
      state.error = null
      state.loading = false
      state.selectedProductAddon = action.payload
    })

    builder.addCase(createProductAddon.pending, state => {
      state.loading = true
      state.error = null
    })
    builder.addCase(createProductAddon.rejected, (state, action) => {
      state.error = action.error.message ? action.error.message : 'Unknown error'
      state.loading = false
      state.selectedProductAddon = null
    })
    builder.addCase(createProductAddon.fulfilled, (state, action) => {
      state.error = null
      state.loading = false
      state.selectedProductAddon = action.payload
    })

    builder.addCase(updateProductAddon.pending, state => {
      state.loading = true
      state.error = null
    })
    builder.addCase(updateProductAddon.rejected, (state, action) => {
      state.error = action.error.message ? action.error.message : 'Unknown error'
      state.loading = false
    })
    builder.addCase(updateProductAddon.fulfilled, (state, action) => {
      state.error = null
      state.loading = false
      state.selectedProductAddon = action.payload
    })

    builder.addCase(deleteProductAddon.pending, state => {
      state.loading = true
      state.error = null
    })
    builder.addCase(deleteProductAddon.rejected, (state, action) => {
      state.error = action.error.message ? action.error.message : 'Unknown error'
      state.loading = false
    })
    builder.addCase(deleteProductAddon.fulfilled, (state, action) => {
      state.error = null
      state.loading = false
    })

    builder.addCase(updateProductAddonStatus.fulfilled, (state, action) => {
      state.error = null
      state.loading = false
      state.selectedProductAddon = action.payload
    })
    builder.addCase(updateProductAddonStatus.pending, state => {
      state.loading = true
      state.error = null
    })
    builder.addCase(updateProductAddonStatus.rejected, (state, action) => {
      state.error = action.error.message ? action.error.message : 'Unknown error'
      state.loading = false
    })
  },
})
export const { resetProductAddon, checkSelectedProductAddon } = productAddonAddonsSlice.actions
export default productAddonAddonsSlice.reducer
