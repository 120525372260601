import React, { useEffect, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { useAppDispatch, useAppSelector } from 'hooks/redux'
import { logout, setActiveClient } from 'features/user/userSlice'
import { Row, Select, Tooltip } from 'antd'
import Cookies from 'js-cookie'
import { LogoutOutlined } from '@ant-design/icons'

const Header = () => {
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const { name, permission } = useAppSelector(state => state.userReducer)
  const { clientsSelect } = useAppSelector(state => state.clientReducer)
  const [selectedClientId, setSelectedClientId] = useState<number | null>(null)
  const exit = () => {
    dispatch(logout()).then(response => {
      navigate('/login')
    })
  }
  useEffect(() => {
    const clientId = Cookies.get('activeClientId')
    if (clientsSelect && clientId && +clientId > 0) {
      setSelectedClientId(+clientId)
    }

    if (clientsSelect?.length && !clientId) {
      setSelectedClientId(clientsSelect[0].id)
      Cookies.set('activeClientId', clientsSelect[0].id.toString(), {
        secure: false,
        sameSite: 'strict',
        expires: 30,
        path: '/',
      })
    }
  }, [clientsSelect])
  return (
    <header>
      <Row justify='space-between' align={'middle'} style={{ width: '100%' }}>
        <Row>
          <Link className={'logo'} to={'/'}>
            APM
          </Link>
          {permission === 0 && (
            <Select
              style={{ width: '150px' }}
              value={selectedClientId}
              options={clientsSelect.map(client => ({
                label: client.name,
                value: client.id,
              }))}
              onChange={(value, option) => {
                dispatch(setActiveClient(value))
                Cookies.set('activeClientId', value.toString(), {
                  secure: false,
                  sameSite: 'strict',
                  expires: 30,
                  path: '/',
                })
                setSelectedClientId(value)
              }}
            />
          )}
        </Row>
        {name && (
          <div className={'user'}>
            Hi, {name}
            <Tooltip title={'Sign Out'} placement={'left'}>
              <LogoutOutlined onClick={() => exit()} className={'exit'} />
            </Tooltip>
          </div>
        )}
      </Row>
    </header>
  )
}
export default Header
