import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import axiosInstance from 'untils'
import { AxiosResponse } from 'axios'
import { LoadBalancerRule, LoadBalancerRuleData, LoadBalancerRuleState } from 'features/loadBalancer/types'
import { Product } from 'features/product/types'
const loadBalancerRuleState: LoadBalancerRuleState = {
  selectedLoadBalancerRule: null,
  loadBalancerRules: [],
  availableProducts: [],
  total: 0,
  error: null,
  loading: false,
}
export const getLoadBalancerRules = createAsyncThunk<
  { items: LoadBalancerRule[]; total: number },
  number,
  { rejectValue: void }
>('getLoadBalancerRulesList', async accountId => {
  const response = await axiosInstance.get<void, AxiosResponse<{ items: LoadBalancerRule[]; total: number }>>(
    `/loadBalancerRule`,
    {
      params: {
        accountId,
      },
    },
  )
  return response.data
})
export const getAvailableProducts = createAsyncThunk<
  { items: Product[] },
  { accountId: number; ruleId?: number },
  { rejectValue: void }
>('getAvailableProducts', async ({ accountId, ruleId }) => {
  const response = await axiosInstance.get<void, AxiosResponse<{ items: Product[] }>>(
    `/loadBalancerRule/availableProducts`,
    {
      params: {
        accountId,
        ruleId,
      },
    },
  )
  return response.data
})
export const getLoadBalancerRule = createAsyncThunk<LoadBalancerRule, number, { rejectValue: void }>(
  'getLoadBalancerRule',
  async id => {
    const response = await axiosInstance.get<void, AxiosResponse<LoadBalancerRule>>(`/loadBalancerRule/${id}`)
    return response.data
  },
)

export const createLoadBalancerRule = createAsyncThunk<LoadBalancerRule, LoadBalancerRuleData, { rejectValue: void }>(
  'createLoadBalancerRule',
  async loadBalancerRule => {
    const response = await axiosInstance.post<void, AxiosResponse<LoadBalancerRule>>(
      `/loadBalancerRule`,
      loadBalancerRule,
    )
    return response.data
  },
)

export const updateLoadBalancerRule = createAsyncThunk<LoadBalancerRule, LoadBalancerRule, { rejectValue: void }>(
  'updateLoadBalancerRule',
  async loadBalancerRule => {
    const response = await axiosInstance.put<void, AxiosResponse<LoadBalancerRule>>(
      `/loadBalancerRule/${loadBalancerRule.id}`,
      loadBalancerRule,
    )
    return response.data
  },
)
export const updateLoadBalancerRuleStatus = createAsyncThunk<
  LoadBalancerRule,
  { id: number; active: boolean },
  { rejectValue: void }
>('updateLoadBalancerRuleStatus', async ({ id, active }) => {
  const response = await axiosInstance.put<void, AxiosResponse<LoadBalancerRule>>(
    `/loadBalancerRule/${id}/statusUpdate`,
    {
      active,
    },
  )
  return response.data
})

export const deleteLoadBalancerRule = createAsyncThunk<boolean, number, { rejectValue: void }>(
  'deleteLoadBalancerRule',
  async (id, { dispatch }) => {
    const response = await axiosInstance.delete<void, AxiosResponse<boolean>>(`/loadBalancerRule/${id}`)
    dispatch(checkSelectedLoadBalancerRule(id))
    return response.data
  },
)

export const loadBalancerRuleAddonsSlice = createSlice({
  name: 'loadBalancerRule',
  initialState: loadBalancerRuleState,
  reducers: {
    resetLoadBalancerRule(state) {
      state.selectedLoadBalancerRule = null
    },
    checkSelectedLoadBalancerRule(state, action) {
      if (state.selectedLoadBalancerRule?.id === action.payload) {
        state.selectedLoadBalancerRule = null
      }
    },
  },
  extraReducers: builder => {
    builder.addCase(getLoadBalancerRules.pending, state => {
      state.loading = true
      state.error = null
    })
    builder.addCase(getLoadBalancerRules.rejected, (state, action) => {
      state.error = action.error.message ? action.error.message : 'Unknown error'
      state.loading = false
      state.loadBalancerRules = []
    })
    builder.addCase(getLoadBalancerRules.fulfilled, (state, action) => {
      state.error = null
      state.loading = false
      state.loadBalancerRules = action.payload.items
    })

    builder.addCase(getAvailableProducts.pending, state => {
      state.loading = true
      state.error = null
    })
    builder.addCase(getAvailableProducts.rejected, (state, action) => {
      state.error = action.error.message ? action.error.message : 'Unknown error'
      state.loading = false
      state.availableProducts = []
    })
    builder.addCase(getAvailableProducts.fulfilled, (state, action) => {
      state.error = null
      state.loading = false
      state.availableProducts = action.payload.items
    })

    builder.addCase(getLoadBalancerRule.pending, state => {
      state.loading = true
      state.error = null
    })
    builder.addCase(getLoadBalancerRule.rejected, (state, action) => {
      state.error = action.error.message ? action.error.message : 'Unknown error'
      state.loading = false
      state.selectedLoadBalancerRule = null
    })
    builder.addCase(getLoadBalancerRule.fulfilled, (state, action) => {
      state.error = null
      state.loading = false
      state.selectedLoadBalancerRule = action.payload
    })

    builder.addCase(createLoadBalancerRule.pending, state => {
      state.loading = true
      state.error = null
    })
    builder.addCase(createLoadBalancerRule.rejected, (state, action) => {
      state.error = action.error.message ? action.error.message : 'Unknown error'
      state.loading = false
      state.selectedLoadBalancerRule = null
    })
    builder.addCase(createLoadBalancerRule.fulfilled, (state, action) => {
      state.error = null
      state.loading = false
      state.selectedLoadBalancerRule = action.payload
    })

    builder.addCase(updateLoadBalancerRule.pending, state => {
      state.loading = true
      state.error = null
    })
    builder.addCase(updateLoadBalancerRule.rejected, (state, action) => {
      state.error = action.error.message ? action.error.message : 'Unknown error'
      state.loading = false
    })
    builder.addCase(updateLoadBalancerRule.fulfilled, (state, action) => {
      state.error = null
      state.loading = false
      state.selectedLoadBalancerRule = action.payload
    })

    builder.addCase(deleteLoadBalancerRule.pending, state => {
      state.loading = true
      state.error = null
    })
    builder.addCase(deleteLoadBalancerRule.rejected, (state, action) => {
      state.error = action.error.message ? action.error.message : 'Unknown error'
      state.loading = false
    })
    builder.addCase(deleteLoadBalancerRule.fulfilled, (state, action) => {
      state.error = null
      state.loading = false
    })

    builder.addCase(updateLoadBalancerRuleStatus.fulfilled, (state, action) => {
      state.error = null
      state.loading = false
      state.selectedLoadBalancerRule = action.payload
    })
    builder.addCase(updateLoadBalancerRuleStatus.pending, state => {
      state.loading = true
      state.error = null
    })
    builder.addCase(updateLoadBalancerRuleStatus.rejected, (state, action) => {
      state.error = action.error.message ? action.error.message : 'Unknown error'
      state.loading = false
    })
  },
})
export const { resetLoadBalancerRule, checkSelectedLoadBalancerRule } = loadBalancerRuleAddonsSlice.actions
export default loadBalancerRuleAddonsSlice.reducer
