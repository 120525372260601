import { useAppDispatch, useAppSelector } from 'hooks/redux'
import { Button, Col, Form, Input, Row, Select } from 'antd'
import { useNavigate } from 'react-router-dom'
import React, { useEffect, useState } from 'react'
import { ClientData } from 'features/client/types'
import { createClient, updateClient } from 'features/client/clientSlice'
import { addNotification } from 'features/notifications/notificationHandler'

const ClientForm = () => {
  const dispatch = useAppDispatch()
  const { error, loading, selectedClient } = useAppSelector(state => state.clientReducer)
  const navigate = useNavigate()

  useEffect(() => {
    if (error) {
      dispatch(
        addNotification({
          type: 'error',
          message: error,
        }),
      )
    }
  }, [error])

  const formSubmit = async (values: ClientData) => {
    if (selectedClient?.id) {
      dispatch(updateClient({ id: selectedClient.id, ...values })).then(response => {
        if (response.payload) {
          dispatch(
            addNotification({
              type: 'success',
              message: 'Client updated successfully',
            }),
          )
        }
      })
    } else {
      dispatch(createClient(values)).then(response => {
        if (response.payload) {
          dispatch(
            addNotification({
              type: 'success',
              message: 'Client created successfully',
            }),
          )
          navigate(`/client/${response.payload.id}`)
        }
      })
    }
  }
  return (
    <>
      <Form onFinish={formSubmit} labelCol={{ span: 24, offset: 0 }}>
        <Row>
          <Col span={24}>
            <Form.Item
              label={'Name'}
              name={'name'}
              rules={[{ required: true, message: 'Please Enter Name' }]}
              initialValue={selectedClient?.name}
            >
              <Input placeholder={'Name'} />
            </Form.Item>
          </Col>
        </Row>
        <Button type={'primary'} htmlType={'submit'} loading={loading}>
          {selectedClient?.id ? 'Update' : 'Create'}
        </Button>
      </Form>
    </>
  )
}
export default ClientForm
