import { useAppDispatch, useAppSelector } from 'hooks/redux'
import { Button, Col, Form, Input, Row } from 'antd'
import { login, signUp } from 'features/user/userSlice'
import { useNavigate } from 'react-router-dom'
import React from 'react'

type loginForm = {
  login: string
  password: string
  email: string
  name: string
}

const SignUp = () => {
  const dispatch = useAppDispatch()
  const { error, loading } = useAppSelector(state => state.userReducer)
  const navigate = useNavigate()
  const formSubmit = async (values: loginForm) => {
    dispatch(signUp(values)).then(response => {
      if (response.payload === true) {
        navigate('/login')
      }
    })
  }
  return (
    <>
      <Form onFinish={formSubmit} className={'login_form'} labelCol={{ span: 24, offset: 0 }}>
        <Form.Item label={'Name'} name={'name'} rules={[{ required: true, message: 'Please Enter Name' }]}>
          <Input placeholder={'Name'} />
        </Form.Item>
        <Form.Item label={'Login'} name={'login'} rules={[{ required: true, message: 'Please Enter Login' }]}>
          <Input placeholder={'Login'} />
        </Form.Item>
        <Form.Item
          label={'Email'}
          name={'email'}
          rules={[{ required: true, message: 'Please Enter Email', type: 'email' }]}
        >
          <Input placeholder={'Email'} />
        </Form.Item>
        <Form.Item label={'Password'} name={'password'} rules={[{ required: true, message: 'Please Enter Password' }]}>
          <Input type={'password'} placeholder={'Password'} />
        </Form.Item>
        <Button type={'primary'} htmlType={'submit'} loading={loading}>
          Sign Up
        </Button>
        {error && <div className={'login_form__error'}>{error}</div>}
      </Form>
    </>
  )
}
export default SignUp
