import React, { useEffect, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { useAppDispatch, useAppSelector } from 'hooks/redux'
import { logout } from 'features/user/userSlice'
import { Layout, Menu } from 'antd'
import { MenuItemType } from 'antd/es/menu/hooks/useItems'
import {
  ApiOutlined,
  BarsOutlined,
  DatabaseFilled,
  DollarOutlined,
  ManOutlined,
  MenuFoldOutlined,
  MenuUnfoldOutlined,
  ShoppingOutlined,
  SlidersOutlined,
  UserAddOutlined,
} from '@ant-design/icons'

const Sidebar = () => {
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const [current, setCurrent] = useState<string>('accounts')
  const [collapsed, setCollapsed] = useState<boolean>(false)
  const { name, permission } = useAppSelector(state => state.userReducer)
  const exit = () => {
    dispatch(logout()).then(response => {
      navigate('/')
    })
  }
  const toggle = () => {
    setCollapsed(!collapsed)
  }
  const menuItems: MenuItemType[] = [
    {
      label: <Link to={'/accounts'}>CRM Accounts</Link>,
      key: 'accounts',
      icon: <UserAddOutlined />,
    },
    {
      label: <Link to={'/offers'}>Offers</Link>,
      key: 'offers',
      icon: <ApiOutlined />,
    },
    {
      label: <Link to={'/loadBalancerRules'}>Load Balancer</Link>,
      key: 'loadBalancerRules',
      icon: <SlidersOutlined />,
    },
    {
      label: <Link to={'/products'}>Products</Link>,
      key: 'products',
      icon: <ShoppingOutlined />,
    },
    {
      label: <Link to={'/campaigns'}>Campaigns</Link>,
      key: 'campaigns',
      icon: <DatabaseFilled />,
    },
    {
      label: <Link to={'/mids'}>MIDs</Link>,
      key: 'mids',
      icon: <DollarOutlined />,
    },
    {
      label: <Link to={'/conversionReport'}>Conversion Report</Link>,
      key: 'conversionReport',
      icon: <DollarOutlined />,
    },
    {
      label: <Link to={'/rebillsReport'}>Rebills Report</Link>,
      key: 'rebillsReport',
      icon: <DollarOutlined />,
    },
  ]
  useEffect(() => {
    menuItems.forEach(item => {
      if (window.location.pathname.includes(item.key.toString())) {
        setCurrent(item.key.toString())
      }
    })
  }, [window.location.pathname])

  if (permission === 0) {
    menuItems.unshift({
      label: <Link to={'/clients'}>Clients</Link>,
      key: 'clients',
      icon: <UserAddOutlined />,
    })
  }
  return (
    <>
      <div onClick={toggle}>
        {!collapsed && <MenuFoldOutlined className={'sidebar__collapse_button'} />}
        {collapsed && <MenuUnfoldOutlined className={'sidebar__collapse_button'} />}
      </div>
      <Layout.Sider className={'sidebar'} collapsed={collapsed}>
        <Menu selectedKeys={[current]} items={menuItems} mode={'vertical'}></Menu>
      </Layout.Sider>
    </>
  )
}
export default Sidebar
