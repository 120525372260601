import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import Cookies from 'js-cookie'
import axiosInstance from 'untils'
import { AxiosResponse } from 'axios'
import { Offer, OfferData, OfferState } from 'features/offer/types'
const offerState: OfferState = {
  selectedOffer: null,
  offers: [],
  offersSelect: [],
  total: 0,
  error: null,
  loading: false,
}
export const getOffersList = createAsyncThunk<
  { items: Offer[]; total: number },
  { page?: number; limit?: number; crmType?: number },
  { rejectValue: void }
>('getOffersList', async ({ page, limit, crmType }) => {
  const response = await axiosInstance.get<void, AxiosResponse<{ items: Offer[]; total: number }>>(`/offer`, {
    params: {
      page,
      limit,
      crmType,
    },
  })
  return response.data
})

export const getOffersListSelect = createAsyncThunk<
  { items: Offer[]; total: number },
  { crmType?: number },
  { rejectValue: void }
>('getOffersListSelect', async ({ crmType }) => {
  const response = await axiosInstance.get<void, AxiosResponse<{ items: Offer[]; total: number }>>(`/offer`, {
    params: {
      page: 1,
      limit: 10000,
      crmType,
    },
  })
  return response.data
})

export const getOffer = createAsyncThunk<Offer, number, { rejectValue: void }>('getOffer', async id => {
  const response = await axiosInstance.get<void, AxiosResponse<Offer>>(`/offer/${id}`)
  return response.data
})

export const createOffer = createAsyncThunk<Offer, OfferData, { rejectValue: void }>('createOffer', async offer => {
  const response = await axiosInstance.post<void, AxiosResponse<Offer>>(`/offer`, offer)
  return response.data
})

export const updateOffer = createAsyncThunk<Offer, Offer, { rejectValue: void }>('updateOffer', async offer => {
  const response = await axiosInstance.put<void, AxiosResponse<Offer>>(`/offer/${offer.id}`, offer)
  return response.data
})
export const deleteOffer = createAsyncThunk<boolean, number, { rejectValue: void }>(
  'deleteOffer',
  async (id, { dispatch }) => {
    const response = await axiosInstance.delete<void, AxiosResponse<boolean>>(`/offer/${id}`)
    dispatch(checkSelectedOffer(id))
    return response.data
  },
)

export const offerSlice = createSlice({
  name: 'offer',
  initialState: offerState,
  reducers: {
    resetOffer(state) {
      state.selectedOffer = null
    },
    checkSelectedOffer(state, action) {
      if (state.selectedOffer?.id === action.payload) {
        state.selectedOffer = null
      }
    },
  },
  extraReducers: builder => {
    builder.addCase(getOffersList.pending, state => {
      state.loading = true
      state.error = null
    })
    builder.addCase(getOffersList.rejected, (state, action) => {
      state.error = action.error.message ? action.error.message : 'Unknown error'
      state.loading = false
      state.offers = []
      state.total = 0
    })
    builder.addCase(getOffersList.fulfilled, (state, action) => {
      state.error = null
      state.loading = false
      state.offers = action.payload.items
      state.total = action.payload.total
    })

    builder.addCase(getOffersListSelect.pending, state => {
      state.loading = true
      state.error = null
    })
    builder.addCase(getOffersListSelect.rejected, (state, action) => {
      state.error = action.error.message ? action.error.message : 'Unknown error'
      state.loading = false
      state.offersSelect = []
    })
    builder.addCase(getOffersListSelect.fulfilled, (state, action) => {
      state.error = null
      state.loading = false
      state.offersSelect = action.payload.items
    })

    builder.addCase(getOffer.pending, state => {
      state.loading = true
      state.error = null
    })
    builder.addCase(getOffer.rejected, (state, action) => {
      state.error = action.error.message ? action.error.message : 'Unknown error'
      state.loading = false
      state.selectedOffer = null
    })
    builder.addCase(getOffer.fulfilled, (state, action) => {
      state.error = null
      state.loading = false
      state.selectedOffer = action.payload
    })

    builder.addCase(createOffer.pending, state => {
      state.loading = true
      state.error = null
    })
    builder.addCase(createOffer.rejected, (state, action) => {
      state.error = action.error.message ? action.error.message : 'Unknown error'
      state.loading = false
      state.selectedOffer = null
    })
    builder.addCase(createOffer.fulfilled, (state, action) => {
      state.error = null
      state.loading = false
      state.selectedOffer = action.payload
    })

    builder.addCase(updateOffer.pending, state => {
      state.loading = true
      state.error = null
    })
    builder.addCase(updateOffer.rejected, (state, action) => {
      state.error = action.error.message ? action.error.message : 'Unknown error'
      state.loading = false
    })
    builder.addCase(updateOffer.fulfilled, (state, action) => {
      state.error = null
      state.loading = false
      state.selectedOffer = action.payload
    })

    builder.addCase(deleteOffer.pending, state => {
      state.loading = true
      state.error = null
    })
    builder.addCase(deleteOffer.rejected, (state, action) => {
      state.error = action.error.message ? action.error.message : 'Unknown error'
      state.loading = false
    })
    builder.addCase(deleteOffer.fulfilled, (state, action) => {
      state.error = null
      state.loading = false
    })
  },
})
export const { resetOffer, checkSelectedOffer } = offerSlice.actions
export default offerSlice.reducer
