import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import Cookies from 'js-cookie'
import axiosInstance from 'untils'
import { AxiosResponse } from 'axios'
import { User, UserState } from 'features/user/types'
const userState: UserState = {
  name: '',
  loading: false,
  error: null,
  permission: -1,
}
export const login = createAsyncThunk<User, { login: string; password: string }, { rejectValue: void }>(
  'login',
  async ({ login, password }) => {
    const fingerprint = window.navigator.userAgent
    const response = await axiosInstance.post<void, AxiosResponse<User>>(`/user`, {
      login,
      password,
      fingerprint,
    })
    return response.data
  },
)
export const signUp = createAsyncThunk<
  boolean,
  { login: string; password: string; name: string; email: string },
  { rejectValue: void }
>('signUp', async ({ login, password, email, name }) => {
  const response = await axiosInstance.post<void, AxiosResponse<boolean>>(`/user/signup`, {
    login,
    password,
    email,
    name,
  })
  return response.data
})
export const logout = createAsyncThunk<boolean, void>('logout', async () => {
  const fingerprint = window.navigator.userAgent
  const response = await axiosInstance.post<void, AxiosResponse<boolean>>(`/user/logout`, {
    fingerprint,
  })
  return response.data
})
export const getUser = createAsyncThunk<User, void>('getUser', async () => {
  const response = await axiosInstance.get<void, AxiosResponse<User>>(`/user/info`)
  return response.data
})

export const setActiveClient = createAsyncThunk<boolean, number, { rejectValue: void }>(
  'setActiveClient',
  async clientId => {
    const response = await axiosInstance.post<void, AxiosResponse<boolean>>(`/user/client`, {
      clientId,
    })
    return response.data
  },
)

export const userSlice = createSlice({
  name: 'user',
  initialState: userState,
  reducers: {
    setUser(state, action) {
      if (action.payload?.name) {
        state.name = action.payload.name
      }
    },
  },
  extraReducers: builder => {
    builder.addCase(login.fulfilled, (state, action) => {
      state.name = action.payload.name
      state.permission = action.payload.permission
      state.loading = false
      state.error = null
      const date = new Date()
      date.setHours(date.getHours() + 1)
      if (action.payload?.refreshToken) {
        Cookies.set('refreshToken', action.payload.refreshToken, {
          secure: false,
          sameSite: 'strict',
          expires: 30,
          path: '/',
        })
      }
      if (action.payload?.token) {
        Cookies.set('accessToken', action.payload.token, {
          secure: false,
          sameSite: 'strict',
          expires: 30,
          path: '/',
        })
      }
    })
    builder.addCase(login.pending, state => {
      state.loading = true
      state.error = null
    })
    builder.addCase(login.rejected, (state, action) => {
      state.error = action.error.message ? action.error.message : 'Unknown error'
      state.loading = false
      state.name = ''
      state.permission = -1
      Cookies.remove('refreshToken')
      Cookies.remove('accessToken')
    })
    builder.addCase(logout.pending, state => {
      state.loading = true
      state.error = null
    })
    builder.addCase(logout.rejected, (state, action) => {
      state.error = action.error.message ? action.error.message : 'Unknown error'
      state.loading = false
      state.name = ''
      state.permission = -1
      Cookies.remove('refreshToken')
      Cookies.remove('accessToken')
    })
    builder.addCase(logout.fulfilled, state => {
      state.error = null
      state.loading = false
      state.name = ''
      state.permission = -1
      Cookies.remove('refreshToken')
      Cookies.remove('accessToken')
    })
    builder.addCase(getUser.fulfilled, (state, action) => {
      state.name = action.payload.name
      state.permission = action.payload.permission
      state.loading = false
      state.error = null
    })
    builder.addCase(getUser.pending, state => {
      state.loading = true
      state.error = null
    })
    builder.addCase(getUser.rejected, (state, action) => {
      state.error = action.error.message ? action.error.message : 'Unknown error'
      state.loading = false
      state.name = ''
      state.permission = -1
      Cookies.remove('refreshToken')
      Cookies.remove('accessToken')
    })

    builder.addCase(signUp.fulfilled, state => {
      state.loading = false
      state.error = null
    })
    builder.addCase(signUp.pending, state => {
      state.loading = true
      state.error = null
    })
    builder.addCase(signUp.rejected, (state, action) => {
      state.error = action.error.message ? action.error.message : 'Unknown error'
      state.loading = false
    })

    builder.addCase(setActiveClient.fulfilled, state => {
      state.loading = false
      state.error = null
    })
    builder.addCase(setActiveClient.pending, state => {
      state.loading = true
      state.error = null
    })
    builder.addCase(setActiveClient.rejected, (state, action) => {
      state.error = action.error.message ? action.error.message : 'Unknown error'
      state.loading = false
    })
  },
})

export default userSlice.reducer
