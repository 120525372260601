import { useAppDispatch, useAppSelector } from 'hooks/redux'
import React, { useEffect, useState } from 'react'
import { resetAccount } from 'features/account/accountSlice'
import AccountForm from 'features/account/components/accountForm'
import { Button, Row } from 'antd'
import { Link } from 'react-router-dom'

const AccountCreatePage = () => {
  const dispatch = useAppDispatch()
  useEffect(() => {
    dispatch(resetAccount())
  }, [])

  return (
    <>
      <Row justify='space-between' align={'middle'}>
        <h3>Account Create</h3>
        <Link to={'/accounts'}>
          <Button>Back</Button>
        </Link>
      </Row>
      <AccountForm />
    </>
  )
}
export default AccountCreatePage
