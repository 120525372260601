import { useAppDispatch, useAppSelector } from 'hooks/redux'
import { Button, Col, Form, Input, Popconfirm, Row, Table, Tooltip } from 'antd'
import { login, signUp } from 'features/user/userSlice'
import { Link, useNavigate } from 'react-router-dom'
import React, { useEffect, useState } from 'react'
import { deleteClient, getClientsList, resetClient } from 'features/client/clientSlice'
import { Client } from 'features/client/types'
import { DeleteFilled, EditFilled } from '@ant-design/icons'
import { saveToCookies } from 'untils/saveToCookies'
import Cookies from 'js-cookie'
import { addNotification } from 'features/notifications/notificationHandler'

const ClientsList = () => {
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const { error, loading, clients, total } = useAppSelector(state => state.clientReducer)
  const [currentPage, setCurrentPage] = useState(1)
  const [limit, setLimit] = useState(10)

  useEffect(() => {
    const page = Cookies.get(window.location.pathname + 'page')
    const limit = Cookies.get(window.location.pathname + 'limit')
    if (page !== undefined && +page > 0) {
      setCurrentPage(+page)
    }
    if (limit !== undefined && +limit > 0) {
      setLimit(+limit)
    }
  }, [])

  useEffect(() => {
    dispatch(getClientsList({ page: currentPage, limit: limit }))
  }, [currentPage, limit])

  const columns = [
    {
      title: 'ID',
      dataIndex: 'id',
      key: 'id',
    },
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'Actions',
      key: 'actions',
      render: (_: string, record: Client) => (
        <div className={'actions'}>
          <Tooltip title={'Edit'} placement={'top'}>
            <EditFilled onClick={() => onEdit(record.id)} />
          </Tooltip>
          <Tooltip title={'Delete'} placement={'top'}>
            <Popconfirm
              title='Delete Client'
              description='Confirm Client remove'
              onConfirm={() => onDelete(record.id)}
              okText='Confirm'
              cancelText='Cancel'
            >
              <DeleteFilled />
            </Popconfirm>
          </Tooltip>
        </div>
      ),
    },
  ]

  const onEdit = (id: number) => {
    navigate(`/client/${id}`)
  }

  const onDelete = (id: number) => {
    dispatch(deleteClient(id)).then(response => {
      if (response.payload) {
        dispatch(
          addNotification({
            type: 'success',
            message: 'Client removed successfully',
          }),
        )
        dispatch(getClientsList({ page: currentPage, limit: limit }))
      }
    })
  }
  const createClient = () => {
    dispatch(resetClient())
    navigate('/client')
  }

  return (
    <>
      <Row justify='space-between' align={'middle'}>
        <h3>Clients List</h3>
        <Button onClick={createClient}>Create Client</Button>
      </Row>

      <Table
        loading={loading}
        columns={columns}
        dataSource={clients.map(client => ({
          key: client.id,
          ...client,
        }))}
        pagination={{
          pageSize: limit,
          current: currentPage,
          showTotal: (total, range) => `${range[0]}-${range[1]} of ${total} items`,
          total: total,
          showSizeChanger: true,
          onChange: (page, pageSize) => {
            setCurrentPage(page)
            setLimit(pageSize)
            saveToCookies(window.location.pathname + 'page', page.toString())
            saveToCookies(window.location.pathname + 'limit', pageSize.toString())
          },
        }}
      />
    </>
  )
}
export default ClientsList
