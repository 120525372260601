import { useAppDispatch, useAppSelector } from 'hooks/redux'
import { Button, Col, Form, Input, InputNumber, Modal, Row, Select } from 'antd'
import { useNavigate } from 'react-router-dom'
import React, { useEffect, useState } from 'react'
import { ProductData } from 'features/product/types'
import { addNotification } from 'features/notifications/notificationHandler'
import {
  createProductAttempt,
  getProductAttempts,
  updateProductAttempt,
} from 'features/productAttempts/productAttemptsSlice'
import { ProductAttemptData } from 'features/productAttempts/types'
import { timeUnit } from 'untils/enums'

const ProductAttemptModal = ({ open, toggle }: { open: boolean; toggle: () => void }) => {
  const dispatch = useAppDispatch()
  const { selectedProduct } = useAppSelector(state => state.productReducer)
  const { error, loading, selectedProductAttempt, productAttempts } = useAppSelector(
    state => state.productAttemptReducer,
  )
  const [form] = Form.useForm()
  const [minDelay, setMinDelay] = useState(1)
  const [maxDelay, setMaxDelay] = useState(1)
  const [delayTimeUnit, setDelayTimeUnit] = useState<'min' | 'hour' | 'day'>('min')

  useEffect(() => {
    form.resetFields()
    if (selectedProductAttempt?.id) {
      let tpmMinDelay = selectedProductAttempt.delayMin
      let tpmMaxDelay = selectedProductAttempt.delayMax
      if (tpmMinDelay % 60 === 0) {
        tpmMinDelay = tpmMinDelay / 60
        tpmMaxDelay = tpmMaxDelay / 60
        setDelayTimeUnit('hour')
      }
      if (tpmMinDelay % 24 === 0) {
        tpmMinDelay = tpmMinDelay / 24
        tpmMaxDelay = tpmMaxDelay / 24
        setDelayTimeUnit('day')
      }
      setMinDelay(tpmMinDelay)
      setMaxDelay(tpmMaxDelay)
    }
  }, [open])

  const formSubmit = async (values: ProductAttemptData) => {
    if (!selectedProduct) {
      return
    }
    values.productId = selectedProduct.id
    if (delayTimeUnit === 'day') {
      values.delayMin = values.delayMin * 60 * 24
      values.delayMax = values.delayMax * 60 * 24
    }
    if (delayTimeUnit === 'hour') {
      values.delayMin = values.delayMin * 60
      values.delayMax = values.delayMax * 60
    }
    if (selectedProductAttempt?.id) {
      dispatch(updateProductAttempt({ id: selectedProductAttempt.id, ...values })).then(response => {
        if (response.payload) {
          dispatch(
            addNotification({
              type: 'success',
              message: 'Product updated successfully',
            }),
          )
          dispatch(getProductAttempts(selectedProduct.id))
          toggle()
        }
      })
    } else {
      dispatch(createProductAttempt(values)).then(response => {
        if (response.payload) {
          dispatch(
            addNotification({
              type: 'success',
              message: 'Product created successfully',
            }),
          )
          dispatch(getProductAttempts(selectedProduct.id))
          toggle()
        }
      })
    }
  }
  if (!selectedProduct) return null
  return (
    <>
      <Modal
        open={open}
        onCancel={toggle}
        onOk={() => form.submit()}
        okText={selectedProductAttempt?.id ? 'Update' : 'Create'}
        cancelText={'Cancel'}
        destroyOnClose={true}
        title={selectedProductAttempt?.id ? 'Update Attempt' : 'Create Attempt'}
      >
        <Form form={form} onFinish={formSubmit} labelCol={{ span: 24, offset: 0 }} wrapperCol={{ span: 24, offset: 0 }}>
          <Row gutter={8}>
            <Col sm={12} xs={24}>
              <Form.Item
                label={'Price'}
                name={'price'}
                rules={[{ required: true, message: 'Please Enter Price' }]}
                initialValue={selectedProductAttempt?.price}
              >
                <InputNumber placeholder={'Price'} min={0} step={0.01} precision={2} />
              </Form.Item>
            </Col>
            <Col sm={12} xs={24}>
              <Form.Item
                label={'Quantity'}
                name={'quantity'}
                rules={[{ required: true, message: 'Please Enter Quantity' }]}
                initialValue={selectedProductAttempt?.quantity ? selectedProductAttempt.quantity : 1}
              >
                <InputNumber placeholder={'Quantity'} min={1} step={1} />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={8}>
            <Col xs={12}>
              <Form.Item
                label={`Min Delay (in ${timeUnit[delayTimeUnit]})`}
                name={'delayMin'}
                rules={[{ required: true, message: 'Please Enter Min Delay' }]}
                initialValue={selectedProductAttempt?.delayMin}
              >
                <Row wrap={false}>
                  <InputNumber
                    style={{ flex: 2 }}
                    placeholder={'Min Delay'}
                    min={1}
                    value={minDelay}
                    onChange={value => {
                      if (value) {
                        setMinDelay(value)
                      }
                    }}
                    onBlur={() => {
                      if (minDelay > maxDelay) {
                        setMaxDelay(minDelay)
                        form.setFieldValue('delayMax', minDelay)
                      }
                    }}
                  />
                  <Select
                    style={{ flex: 1 }}
                    value={delayTimeUnit}
                    options={[
                      { value: 'min', label: 'Min' },
                      { value: 'hour', label: 'Hour' },
                      { value: 'day', label: 'Day' },
                    ]}
                    onChange={value => {
                      setDelayTimeUnit(value)
                    }}
                  />
                </Row>
              </Form.Item>
            </Col>
            <Col xs={12}>
              <Form.Item
                label={`Max Delay (in ${timeUnit[delayTimeUnit]})`}
                name={'delayMax'}
                rules={[{ required: true, message: 'Please Enter Max Delay' }]}
                initialValue={selectedProductAttempt?.delayMax}
              >
                <Row wrap={false}>
                  <InputNumber
                    style={{ flex: 2 }}
                    placeholder={'Max Delay'}
                    min={1}
                    value={maxDelay}
                    onChange={value => {
                      if (value) {
                        setMaxDelay(value)
                      }
                    }}
                    onBlur={() => {
                      if (minDelay > maxDelay) {
                        setMinDelay(maxDelay)
                        form.setFieldValue('delayMin', maxDelay)
                      }
                    }}
                  />
                  <Select
                    style={{ flex: 1 }}
                    value={delayTimeUnit}
                    options={[
                      { value: 'min', label: 'Min' },
                      { value: 'hour', label: 'Hour' },
                      { value: 'day', label: 'Day' },
                    ]}
                    onChange={value => {
                      setDelayTimeUnit(value)
                    }}
                  />
                </Row>
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Modal>
    </>
  )
}
export default ProductAttemptModal
