import { useAppDispatch, useAppSelector } from 'hooks/redux'
import React, { useEffect, useState } from 'react'
import { getAvailableProducts, resetLoadBalancerRule } from 'features/loadBalancer/loadBalancerSlice'
import LoadBalancerRuleForm from 'features/loadBalancer/components/loadBalancerForm'
import { Button, Row } from 'antd'
import { Link } from 'react-router-dom'
import { getMidsListSelect } from 'features/mid/midSlice'
import Cookies from 'js-cookie'

const LoadBalancerRuleCreatePage = () => {
  const dispatch = useAppDispatch()
  const selectedAccount = Cookies.get('activeAccountId')
  useEffect(() => {
    dispatch(resetLoadBalancerRule())
  }, [])
  useEffect(() => {
    if (selectedAccount) {
      dispatch(getAvailableProducts({ accountId: +selectedAccount }))
      dispatch(getMidsListSelect(+selectedAccount))
    }
  }, [selectedAccount])

  return (
    <>
      <Row justify='space-between' align={'middle'}>
        <h3>LoadBalancerRule Create</h3>
        <Link to={'/loadBalancerRules'}>
          <Button>Back</Button>
        </Link>
      </Row>
      <LoadBalancerRuleForm />
    </>
  )
}
export default LoadBalancerRuleCreatePage
