import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import Cookies from 'js-cookie'
import axiosInstance from 'untils'
import { AxiosResponse } from 'axios'
import { Account, AccountData, AccountState } from 'features/account/types'
const accountState: AccountState = {
  selectedAccount: null,
  accounts: [],
  accountsSelect: [],
  total: 0,
  error: null,
  loading: false,
}
export const getAccountsList = createAsyncThunk<
  { items: Account[]; total: number },
  { page?: number; limit?: number; crmType?: number },
  { rejectValue: void }
>('getAccountsList', async ({ page, limit, crmType }) => {
  const response = await axiosInstance.get<void, AxiosResponse<{ items: Account[]; total: number }>>(`/account`, {
    params: {
      page,
      limit,
      crmType,
    },
  })
  return response.data
})

export const getAccountsListSelect = createAsyncThunk<
  { items: Account[]; total: number },
  { crmType?: number },
  { rejectValue: void }
>('getAccountsListSelect', async ({ crmType }) => {
  const response = await axiosInstance.get<void, AxiosResponse<{ items: Account[]; total: number }>>(`/account`, {
    params: {
      page: 1,
      limit: 10000,
      crmType,
    },
  })
  return response.data
})

export const getAccount = createAsyncThunk<Account, number, { rejectValue: void }>('getAccount', async id => {
  const response = await axiosInstance.get<void, AxiosResponse<Account>>(`/account/${id}`)
  return response.data
})

export const createAccount = createAsyncThunk<Account, AccountData, { rejectValue: void }>(
  'createAccount',
  async account => {
    const response = await axiosInstance.post<void, AxiosResponse<Account>>(`/account`, account)
    return response.data
  },
)

export const updateAccount = createAsyncThunk<Account, Account, { rejectValue: void }>(
  'updateAccount',
  async account => {
    const response = await axiosInstance.put<void, AxiosResponse<Account>>(`/account/${account.id}`, account)
    return response.data
  },
)
export const deleteAccount = createAsyncThunk<boolean, number, { rejectValue: void }>(
  'deleteAccount',
  async (id, { dispatch }) => {
    const response = await axiosInstance.delete<void, AxiosResponse<boolean>>(`/account/${id}`)
    dispatch(checkSelectedAccount(id))
    return response.data
  },
)

export const accountSlice = createSlice({
  name: 'account',
  initialState: accountState,
  reducers: {
    resetAccount(state) {
      state.selectedAccount = null
    },
    checkSelectedAccount(state, action) {
      if (state.selectedAccount?.id === action.payload) {
        state.selectedAccount = null
      }
    },
  },
  extraReducers: builder => {
    builder.addCase(getAccountsList.pending, state => {
      state.loading = true
      state.error = null
    })
    builder.addCase(getAccountsList.rejected, (state, action) => {
      state.error = action.error.message ? action.error.message : 'Unknown error'
      state.loading = false
      state.accounts = []
      state.total = 0
    })
    builder.addCase(getAccountsList.fulfilled, (state, action) => {
      state.error = null
      state.loading = false
      state.accounts = action.payload.items
      state.total = action.payload.total
    })

    builder.addCase(getAccountsListSelect.pending, state => {
      state.loading = true
      state.error = null
    })
    builder.addCase(getAccountsListSelect.rejected, (state, action) => {
      state.error = action.error.message ? action.error.message : 'Unknown error'
      state.loading = false
      state.accountsSelect = []
    })
    builder.addCase(getAccountsListSelect.fulfilled, (state, action) => {
      state.error = null
      state.loading = false
      state.accountsSelect = action.payload.items
    })

    builder.addCase(getAccount.pending, state => {
      state.loading = true
      state.error = null
    })
    builder.addCase(getAccount.rejected, (state, action) => {
      state.error = action.error.message ? action.error.message : 'Unknown error'
      state.loading = false
      state.selectedAccount = null
    })
    builder.addCase(getAccount.fulfilled, (state, action) => {
      state.error = null
      state.loading = false
      state.selectedAccount = action.payload
    })

    builder.addCase(createAccount.pending, state => {
      state.loading = true
      state.error = null
    })
    builder.addCase(createAccount.rejected, (state, action) => {
      state.error = action.error.message ? action.error.message : 'Unknown error'
      state.loading = false
      state.selectedAccount = null
    })
    builder.addCase(createAccount.fulfilled, (state, action) => {
      state.error = null
      state.loading = false
      state.selectedAccount = action.payload
    })

    builder.addCase(updateAccount.pending, state => {
      state.loading = true
      state.error = null
    })
    builder.addCase(updateAccount.rejected, (state, action) => {
      state.error = action.error.message ? action.error.message : 'Unknown error'
      state.loading = false
    })
    builder.addCase(updateAccount.fulfilled, (state, action) => {
      state.error = null
      state.loading = false
      state.selectedAccount = action.payload
    })

    builder.addCase(deleteAccount.pending, state => {
      state.loading = true
      state.error = null
    })
    builder.addCase(deleteAccount.rejected, (state, action) => {
      state.error = action.error.message ? action.error.message : 'Unknown error'
      state.loading = false
    })
    builder.addCase(deleteAccount.fulfilled, (state, action) => {
      state.error = null
      state.loading = false
    })
  },
})
export const { resetAccount, checkSelectedAccount } = accountSlice.actions
export default accountSlice.reducer
