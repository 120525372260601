export enum CRMType {
  response = 0,
  konnektive = 1,
  sticky = 2,
  sublytics = 3,
}
export enum CRMName {
  'Response CRM',
  'Konnektive CRM',
  'Sticky CRM',
  'Sublytics CRM',
}
export enum timeUnit {
  min = 'Minutes',
  hour = 'Hours',
  day = 'Days',
}
export enum GroupByType {
  affId = 'Affilate Id',
  bin = 'Bin',
  date = 'Date',
}
