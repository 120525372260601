import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import Cookies from 'js-cookie'
import axiosInstance from 'untils'
import { AxiosResponse } from 'axios'
import { Report, ReportRebills, ReportState } from 'features/report/types'
const reportState: ReportState = {
  report: null,
  reportRebills: null,
  error: null,
  loading: false,
}
export const getReport = createAsyncThunk<
  Report,
  {
    dateFrom: string
    dateTo: string
    offerId?: number
    groupBy?: 'affId' | 'bin'
  },
  { rejectValue: void }
>('createReport', async data => {
  const response = await axiosInstance.post<void, AxiosResponse<Report>>(`/report/conversion`, data)
  return response.data
})

export const getReportRebills = createAsyncThunk<
  ReportRebills,
  {
    dateFrom: string
    dateTo: string
    offerId?: number
    groupBy?: 'affId' | 'bin'
  },
  { rejectValue: void }
>('createReportRebills', async data => {
  const response = await axiosInstance.post<void, AxiosResponse<ReportRebills>>(`/report/rebill`, data)
  return response.data
})

export const reportSlice = createSlice({
  name: 'report',
  initialState: reportState,
  reducers: {
    resetReport(state) {
      state.report = null
    },
  },
  extraReducers: builder => {
    builder.addCase(getReport.pending, state => {
      state.loading = true
      state.error = null
    })
    builder.addCase(getReport.rejected, (state, action) => {
      state.error = action.error.message ? action.error.message : 'Unknown error'
      state.loading = false
      state.report = null
    })
    builder.addCase(getReport.fulfilled, (state, action) => {
      state.error = null
      state.loading = false
      state.report = action.payload
    })
    builder.addCase(getReportRebills.pending, state => {
      state.loading = true
      state.error = null
    })
    builder.addCase(getReportRebills.rejected, (state, action) => {
      state.error = action.error.message ? action.error.message : 'Unknown error'
      state.loading = false
      state.reportRebills = null
    })
    builder.addCase(getReportRebills.fulfilled, (state, action) => {
      state.error = null
      state.loading = false
      state.reportRebills = action.payload
    })
  },
})
export const { resetReport } = reportSlice.actions
export default reportSlice.reducer
