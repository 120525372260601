import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import axiosInstance from 'untils'
import { AxiosResponse } from 'axios'
import { Mid, MidData, MidState } from 'features/mid/types'
const midState: MidState = {
  selectedMid: null,
  mids: [],
  midsSelect: [],
  total: 0,
  error: null,
  loading: false,
}
export const getMidsList = createAsyncThunk<
  { items: Mid[]; total: number },
  { accountId: number; page?: number; limit?: number; crmType?: number },
  { rejectValue: void }
>('getMidsList', async ({ page, limit, accountId }) => {
  const response = await axiosInstance.get<void, AxiosResponse<{ items: Mid[]; total: number }>>(`/mid`, {
    params: {
      page,
      limit,
      accountId,
    },
  })
  return response.data
})

export const getMidsListSelect = createAsyncThunk<{ items: Mid[] }, number, { rejectValue: void }>(
  'getMidsListSelect',
  async accountId => {
    const response = await axiosInstance.get<void, AxiosResponse<{ items: Mid[] }>>(`/mid`, {
      params: {
        page: 1,
        limit: 10000,
        accountId,
      },
    })
    return response.data
  },
)

export const getMid = createAsyncThunk<Mid, number, { rejectValue: void }>('getMid', async id => {
  const response = await axiosInstance.get<void, AxiosResponse<Mid>>(`/mid/${id}`)
  return response.data
})

export const createMid = createAsyncThunk<Mid, MidData, { rejectValue: void }>('createMid', async mid => {
  const response = await axiosInstance.post<void, AxiosResponse<Mid>>(`/mid`, mid)
  return response.data
})

export const updateMid = createAsyncThunk<Mid, Mid, { rejectValue: void }>('updateMid', async mid => {
  const response = await axiosInstance.put<void, AxiosResponse<Mid>>(`/mid/${mid.id}`, mid)
  return response.data
})
export const deleteMid = createAsyncThunk<boolean, { id: number; accountId: number }, { rejectValue: void }>(
  'deleteMid',
  async ({ id, accountId }, { dispatch }) => {
    const response = await axiosInstance.delete<void, AxiosResponse<boolean>>(`/mid/${id}`)
    dispatch(checkSelectedMid(id))
    return response.data
  },
)

export const importMids = createAsyncThunk<boolean, number, { rejectValue: void }>(
  'importMids',
  async accountId =>
    await axiosInstance.post<void, boolean>(`/mid/import`, {
      accountId: accountId,
    }),
)

export const midSlice = createSlice({
  name: 'mid',
  initialState: midState,
  reducers: {
    resetMid(state) {
      state.selectedMid = null
    },
    checkSelectedMid(state, action) {
      if (state.selectedMid?.id === action.payload) {
        state.selectedMid = null
      }
    },
  },
  extraReducers: builder => {
    builder.addCase(getMidsList.pending, state => {
      state.loading = true
      state.error = null
    })
    builder.addCase(getMidsList.rejected, (state, action) => {
      state.error = action.error.message ? action.error.message : 'Unknown error'
      state.loading = false
      state.mids = []
      state.total = 0
    })
    builder.addCase(getMidsList.fulfilled, (state, action) => {
      state.error = null
      state.loading = false
      state.mids = action.payload.items
      state.total = action.payload.total
    })

    builder.addCase(getMidsListSelect.pending, state => {
      state.loading = true
      state.error = null
    })
    builder.addCase(getMidsListSelect.rejected, (state, action) => {
      state.error = action.error.message ? action.error.message : 'Unknown error'
      state.loading = false
      state.midsSelect = []
    })
    builder.addCase(getMidsListSelect.fulfilled, (state, action) => {
      state.error = null
      state.loading = false
      state.midsSelect = action.payload.items
    })

    builder.addCase(getMid.pending, state => {
      state.loading = true
      state.error = null
    })
    builder.addCase(getMid.rejected, (state, action) => {
      state.error = action.error.message ? action.error.message : 'Unknown error'
      state.loading = false
      state.selectedMid = null
    })
    builder.addCase(getMid.fulfilled, (state, action) => {
      state.error = null
      state.loading = false
      state.selectedMid = action.payload
    })

    builder.addCase(createMid.pending, state => {
      state.loading = true
      state.error = null
    })
    builder.addCase(createMid.rejected, (state, action) => {
      state.error = action.error.message ? action.error.message : 'Unknown error'
      state.loading = false
      state.selectedMid = null
    })
    builder.addCase(createMid.fulfilled, (state, action) => {
      state.error = null
      state.loading = false
      state.selectedMid = action.payload
    })

    builder.addCase(updateMid.pending, state => {
      state.loading = true
      state.error = null
    })
    builder.addCase(updateMid.rejected, (state, action) => {
      state.error = action.error.message ? action.error.message : 'Unknown error'
      state.loading = false
    })
    builder.addCase(updateMid.fulfilled, (state, action) => {
      state.error = null
      state.loading = false
      state.selectedMid = action.payload
    })

    builder.addCase(deleteMid.pending, state => {
      state.loading = true
      state.error = null
    })
    builder.addCase(deleteMid.rejected, (state, action) => {
      state.error = action.error.message ? action.error.message : 'Unknown error'
      state.loading = false
    })
    builder.addCase(deleteMid.fulfilled, (state, action) => {
      state.error = null
      state.loading = false
    })

    builder.addCase(importMids.fulfilled, (state, action) => {
      state.error = null
      state.loading = false
    })
    builder.addCase(importMids.pending, state => {
      state.loading = true
      state.error = null
    })
    builder.addCase(importMids.rejected, (state, action) => {
      state.error = action.error.message ? action.error.message : 'Unknown error'
      state.loading = false
    })
  },
})
export const { resetMid, checkSelectedMid } = midSlice.actions
export default midSlice.reducer
