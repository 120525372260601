import { useAppDispatch, useAppSelector } from 'hooks/redux'
import { Button, Col, Form, Input, InputNumber, Row, Select } from 'antd'
import { useNavigate } from 'react-router-dom'
import React, { useEffect, useState } from 'react'
import { CRMType } from 'untils/enums'
import { addNotification } from 'features/notifications/notificationHandler'
import {
  createLoadBalancerRule,
  getAvailableProducts,
  resetLoadBalancerRule,
  updateLoadBalancerRule,
} from 'features/loadBalancer/loadBalancerSlice'
import { LoadBalancerRuleData, LoadBalancerRuleMid, LoadBalancerRuleProduct } from 'features/loadBalancer/types'
import { Product } from 'features/product/types'
import { getAccountsList } from 'features/account/accountSlice'
import Cookies from 'js-cookie'
import { getMidsListSelect } from 'features/mid/midSlice'
import { makeKey } from 'untils'
import MidRow from 'features/loadBalancer/components/midRow'

const LoadBalancerRuleForm = () => {
  const dispatch = useAppDispatch()
  const { error: midsError } = useAppSelector(state => state.midReducer)
  const { error, loading, selectedLoadBalancerRule, availableProducts } = useAppSelector(
    state => state.loadBalancerReducer,
  )
  const selectedAccount = Cookies.get('activeAccountId')
  const navigate = useNavigate()
  const [products, setProducts] = useState<LoadBalancerRuleProduct[]>([])
  const [mids, setMids] = useState<LoadBalancerRuleMid[]>([])
  const [midsFormError, setMidsFormError] = useState<string>('')

  useEffect(() => {
    if (selectedLoadBalancerRule?.id) {
      setProducts(selectedLoadBalancerRule.products)
      setMids(
        selectedLoadBalancerRule.mids.map(mid => ({
          ...mid,
          id: mid.midId,
          key: makeKey(),
        })),
      )
    }
  }, [selectedLoadBalancerRule])

  useEffect(() => {
    if (error) {
      dispatch(
        addNotification({
          type: 'error',
          message: error,
        }),
      )
    }
    if (midsError) {
      dispatch(
        addNotification({
          type: 'error',
          message: midsError,
        }),
      )
    }
  }, [error, midsError])

  const addMid = () => {
    setMids([
      ...mids,
      {
        key: makeKey(),
        id: 0,
        midId: 0,
        percent: 0,
      },
    ])
  }
  const updateMid = (key: string, percent: number, id: number) => {
    setMids(
      mids.map(mid => {
        if (mid.key === key) {
          return {
            ...mid,
            percent,
            id,
            midId: id,
          }
        }
        return mid
      }),
    )
  }

  const formSubmit = async (values: LoadBalancerRuleData) => {
    let totalPercent = 0
    if (!mids.length) {
      setMidsFormError('Please Select MIDs')
      return
    }
    let correctMids = true
    mids.forEach(mid => {
      if (!mid.id) {
        correctMids = false
        return
      }
      totalPercent += mid.percent
    })
    if (!correctMids) {
      setMidsFormError('Please Set MID or remove empty')
      return
    }
    if (totalPercent !== 100) {
      setMidsFormError('Total Percent should be equal 100')
      return
    }
    if (!selectedAccount) {
      navigate('/loadBalancerRules')
      return
    }
    values.accountId = +selectedAccount
    values.mids = mids
    if (selectedLoadBalancerRule?.id) {
      dispatch(updateLoadBalancerRule({ id: selectedLoadBalancerRule.id, ...values })).then(response => {
        if (response.payload) {
          dispatch(
            addNotification({
              type: 'success',
              message: 'LoadBalancerRule updated successfully',
            }),
          )
        }
      })
    } else {
      dispatch(createLoadBalancerRule(values)).then(response => {
        if (response.payload) {
          dispatch(
            addNotification({
              type: 'success',
              message: 'LoadBalancerRule created successfully',
            }),
          )
          navigate(`/loadBalancerRule/${response.payload.id}`)
        }
      })
    }
  }

  return (
    <>
      <Form onFinish={formSubmit} labelCol={{ span: 24, offset: 0 }}>
        <Row gutter={16} style={{ marginBottom: 20 }}>
          <Col sm={12} xs={24}>
            <h4>Products:</h4>
            <Form.Item
              name={'products'}
              rules={[{ required: true, message: 'Please Select Products' }]}
              initialValue={selectedLoadBalancerRule?.products.map(product => product.productId)}
            >
              <Select
                className='loadBalancer__products'
                style={{ width: '100%' }}
                showSearch={true}
                filterOption={(input, option) => (option?.label ?? '').toLowerCase().includes(input.toLowerCase())}
                options={availableProducts.map(product => ({
                  label: `${product.name} (${product.externalId})`,
                  value: product.id,
                }))}
                allowClear={true}
                mode='multiple'
              />
            </Form.Item>
          </Col>
          <Col sm={12} xs={24}>
            <h4>MIDs:</h4>
            <Row key={'headers'} wrap={false} style={{ marginBottom: 10 }}>
              <Col md={4} sm={9}>
                Percent
              </Col>
              <Col md={20} sm={15}>
                MID
              </Col>
            </Row>
            {mids.map(mid => (
              <MidRow mid={mid} key={mid.key} usedMids={mids.map(mid => mid.id)} updateMid={updateMid} />
            ))}
            <Button style={{ width: '100%' }} onClick={() => addMid()}>
              Add MID
            </Button>
            {midsFormError && <div className={'error'}>{midsFormError}</div>}
          </Col>
        </Row>

        <Button type={'primary'} htmlType={'submit'} loading={loading}>
          {selectedLoadBalancerRule?.id ? 'Update' : 'Create'}
        </Button>
      </Form>
    </>
  )
}
export default LoadBalancerRuleForm
