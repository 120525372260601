import { useAppDispatch, useAppSelector } from 'hooks/redux'
import { Button, Col, Popconfirm, Row, Select, Table, Tooltip } from 'antd'
import { useNavigate } from 'react-router-dom'
import React, { useEffect, useState } from 'react'
import { deleteMid, getMidsList, importMids } from 'features/mid/midSlice'
import { Mid } from 'features/mid/types'
import { DeleteFilled } from '@ant-design/icons'
import { getAccountsList, getAccountsListSelect } from 'features/account/accountSlice'
import { CRMType } from 'untils/enums'
import { addNotification } from 'features/notifications/notificationHandler'
import Cookies from 'js-cookie'
import { removeFromCookies, saveToCookies } from 'untils/saveToCookies'

const MidsList = () => {
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const { error, loading, mids, total } = useAppSelector(state => state.midReducer)
  const {
    error: accountsError,
    loading: accountsLoading,
    accountsSelect,
  } = useAppSelector(state => state.accountReducer)
  const [currentPage, setCurrentPage] = useState(1)
  const [limit, setLimit] = useState(10)
  const [accountId, setAccountId] = useState<null | number>(null)
  const [crmType, setCrmType] = useState(-1)

  useEffect(() => {
    const account = Cookies.get('activeAccountId')
    const crm = Cookies.get('crmType')
    const page = Cookies.get(window.location.pathname + 'page')
    const limit = Cookies.get(window.location.pathname + 'limit')
    if (account !== undefined && +account > 0) {
      setAccountId(+account)
    }
    if (crm !== undefined && +crm >= -1) {
      setCrmType(+crm)
    }
    if (page !== undefined && +page > 0) {
      setCurrentPage(+page)
    }
    if (limit !== undefined && +limit > 0) {
      setLimit(+limit)
    }
  }, [])

  useEffect(() => {
    if (accountId !== null) {
      dispatch(getMidsList({ page: currentPage, limit: limit, accountId }))
    }
  }, [currentPage, limit, accountId])
  useEffect(() => {
    if (crmType > -1) {
      dispatch(getAccountsListSelect({ crmType }))
    } else {
      dispatch(getAccountsListSelect({}))
    }
  }, [crmType])

  useEffect(() => {
    if (error) {
      dispatch(
        addNotification({
          type: 'error',
          message: error,
        }),
      )
    }
    if (accountsError) {
      dispatch(
        addNotification({
          type: 'error',
          message: accountsError,
        }),
      )
    }
  }, [error, accountsError])

  const columns = [
    {
      title: 'ID',
      dataIndex: 'id',
      key: 'id',
    },
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'Ext. Id',
      dataIndex: 'externalId',
      key: 'externalId',
    },
    {
      title: 'Actions',
      key: 'actions',
      render: (_: string, record: Mid) => (
        <div className={'actions'}>
          {/*<Link to={`/mid/${record.id}`}>
            <EditFilled />
          </Link>*/}
          <Tooltip title={'Delete'} placement={'top'}>
            <Popconfirm
              title='Delete MID'
              description='Confirm MID remove'
              onConfirm={() => onDelete(record.id)}
              okText='Confirm'
              cancelText='Cancel'
            >
              <DeleteFilled />
            </Popconfirm>
          </Tooltip>
        </div>
      ),
    },
  ]

  const onDelete = (id: number) => {
    if (accountId !== null) {
      dispatch(deleteMid({ id, accountId })).then(response => {
        if (response.payload) {
          dispatch(
            addNotification({
              type: 'success',
              message: 'MID removed successfully',
            }),
          )
          dispatch(getMidsList({ page: currentPage, limit: limit, accountId }))
        }
      })
    }
  }
  const importMidsFromCrm = () => {
    if (accountId !== null) {
      dispatch(importMids(accountId)).then(response => {
        if (response.payload) {
          dispatch(
            addNotification({
              type: 'success',
              message: 'MIDs imported successfully',
            }),
          )
          dispatch(getMidsList({ page: currentPage, limit: limit, accountId }))
        }
      })
    }
  }

  return (
    <>
      <Row justify='space-between' align={'middle'}>
        <h3>MIDs List</h3>
        {accountId !== null && <Button onClick={importMidsFromCrm}>Import Mids</Button>}
      </Row>
      <Row justify='start' gutter={8}>
        <Col md={6} sm={12} xs={24}>
          <Select
            style={{ width: '100%' }}
            placeholder={'Please Select CRM Type'}
            value={crmType}
            options={[
              { label: 'All', value: -1 },
              { label: 'Response', value: CRMType.response },
              { label: 'Konnektive', value: CRMType.konnektive },
              { label: 'Sticky', value: CRMType.sticky },
              { label: 'Sublytics', value: CRMType.sublytics },
            ]}
            onChange={(value, option) => {
              if (value !== -1) {
                setAccountId(null)
                removeFromCookies('activeAccountId')
              }
              setCrmType(value)
              saveToCookies('crmType', value.toString())
            }}
          />
        </Col>
        <Col md={6} sm={12} xs={24}>
          <Select
            loading={accountsLoading}
            style={{ width: '100%' }}
            placeholder={'Account'}
            value={accountId}
            options={accountsSelect.map(account => ({ label: account.name, value: account.id }))}
            onChange={(value, option) => {
              setAccountId(value)
              saveToCookies('crmType', value.toString())
            }}
          />
        </Col>
      </Row>
      {accountId !== null ? (
        <Table
          loading={loading}
          columns={columns}
          dataSource={mids.map(mid => ({
            key: mid.id,
            ...mid,
          }))}
          pagination={{
            pageSize: limit,
            current: currentPage,
            showTotal: (total, range) => `${range[0]}-${range[1]} of ${total} items`,
            total: total,
            showSizeChanger: true,
            onChange: (page, pageSize) => {
              setCurrentPage(page)
              setLimit(pageSize)
              saveToCookies(window.location.pathname + 'page', page.toString())
              saveToCookies(window.location.pathname + 'limit', pageSize.toString())
            },
          }}
        />
      ) : (
        !accountsLoading && <div className={'empty_data'}>Please select CRM Account</div>
      )}
    </>
  )
}
export default MidsList
