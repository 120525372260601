import { useAppDispatch, useAppSelector } from 'hooks/redux'
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom'
import React, { useEffect, useState } from 'react'
import { getClient } from 'features/client/clientSlice'
import ClientForm from 'features/client/components/clientForm'
import { addNotification } from 'features/notifications/notificationHandler'
import { Button, Row, Spin } from 'antd'

const ClientEditPage = () => {
  const dispatch = useAppDispatch()
  const { error, loading, selectedClient } = useAppSelector(state => state.clientReducer)

  const { id } = useParams()
  useEffect(() => {
    if (id && +id > 0) {
      dispatch(getClient(+id))
    }
  }, [id])

  if (loading) return <Spin fullscreen={true} />

  if (!selectedClient) return null

  return (
    <>
      <Row justify='space-between' align={'middle'}>
        <h3>Client Update</h3>
        <Link to={'/clients'}>
          <Button>Back</Button>
        </Link>
      </Row>
      <ClientForm />
    </>
  )
}
export default ClientEditPage
