import Cookies from 'js-cookie'

export const saveToCookies = (name: string, value: string) => {
  Cookies.set(name, value, {
    secure: false,
    sameSite: 'strict',
    expires: 30,
    path: '/',
  })
}
export const removeFromCookies = (name: string) => {
  Cookies.remove(name)
}
