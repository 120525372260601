import { configureStore } from '@reduxjs/toolkit'
import userReducer from 'features/user/userSlice'
import notificationHandler from 'features/notifications/notificationHandler'
import accountReducer from 'features/account/accountSlice'
import productReducer from 'features/product/productSlice'
import clientReducer from 'features/client/clientSlice'
import campaignReducer from 'features/campaign/campaignSlice'
import midReducer from 'features/mid/midSlice'
import productAttemptReducer from 'features/productAttempts/productAttemptsSlice'
import productAddonReducer from 'features/productAddons/productAddonsSlice'
import productRebillReducer from 'features/productRebills/productRebillsSlice'
import loadBalancerReducer from 'features/loadBalancer/loadBalancerSlice'
import offerReducer from 'features/offer/offerSlice'
import reportReducer from 'features/report/reportSlice'
export const store = configureStore({
  reducer: {
    userReducer,
    notificationHandler,
    accountReducer,
    productReducer,
    clientReducer,
    campaignReducer,
    midReducer,
    productAttemptReducer,
    productAddonReducer,
    productRebillReducer,
    loadBalancerReducer,
    offerReducer,
    reportReducer,
  },
})

export type RootState = ReturnType<typeof store.getState>
export type AppStore = typeof store
export type AppDispatch = typeof store.dispatch

export default store
