import { useAppDispatch, useAppSelector } from 'hooks/redux'
import { Button, Col, Form, Input, InputNumber, Row, Select } from 'antd'
import { useNavigate } from 'react-router-dom'
import React, { useEffect, useState } from 'react'
import { CRMType } from 'untils/enums'
import { addNotification } from 'features/notifications/notificationHandler'
import {
  createLoadBalancerRule,
  getAvailableProducts,
  resetLoadBalancerRule,
  updateLoadBalancerRule,
} from 'features/loadBalancer/loadBalancerSlice'
import { LoadBalancerRuleData, LoadBalancerRuleMid } from 'features/loadBalancer/types'
import { Product } from 'features/product/types'
import { getAccountsList } from 'features/account/accountSlice'
import Cookies from 'js-cookie'
import { getMidsListSelect } from 'features/mid/midSlice'
import { makeKey } from 'untils'
import { Mid } from 'features/mid/types'

const MidRow = ({
  mid,
  usedMids,
  updateMid,
}: {
  mid: LoadBalancerRuleMid
  usedMids: number[]
  updateMid: (key: string, percent: number, id: number) => void
}) => {
  const { midsSelect } = useAppSelector(state => state.midReducer)
  const [percent, setPercent] = useState<number>(0)
  const [id, setId] = useState<number | null>(null)
  useEffect(() => {
    if (mid) {
      if (mid.id) setId(mid.id)
      setPercent(mid.percent)
    }
  }, [mid])
  useEffect(() => {
    if (id && mid?.key) {
      updateMid(mid.key, percent, id)
    }
  }, [id])
  return (
    <>
      <Row key={mid.key} wrap={false} style={{ marginBottom: 20 }} gutter={8}>
        <Col md={4} sm={9}>
          <InputNumber
            min={0}
            max={100}
            value={percent}
            onChange={value => {
              if (value) {
                setPercent(value)
                mid.percent = value
              }
            }}
          />
        </Col>
        <Col md={20} sm={15}>
          <Select
            placeholder={'Select MID'}
            style={{ width: '100%' }}
            showSearch={true}
            filterOption={(input, option) => (option?.label ?? '').toLowerCase().includes(input.toLowerCase())}
            options={midsSelect
              .filter(filterMid => !usedMids.includes(filterMid.id) || filterMid.id === mid.id)
              .map(mid => ({ label: `${mid.name} (${mid.externalId})`, value: mid.id }))}
            value={id}
            onChange={(value, option) => {
              setId(value)
              if (value) {
                mid.id = value
              }
            }}
          />
        </Col>
      </Row>
    </>
  )
}
export default MidRow
