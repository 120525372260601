import { useAppDispatch, useAppSelector } from 'hooks/redux'
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom'
import React, { useEffect, useState } from 'react'
import { getAccount } from 'features/account/accountSlice'
import AccountForm from 'features/account/components/accountForm'
import { addNotification } from 'features/notifications/notificationHandler'
import { Button, Row, Spin } from 'antd'

const AccountEditPage = () => {
  const dispatch = useAppDispatch()
  const { error, loading, selectedAccount } = useAppSelector(state => state.accountReducer)

  const { id } = useParams()
  useEffect(() => {
    if (id && +id > 0) {
      dispatch(getAccount(+id))
    }
  }, [id])

  if (loading) return <Spin fullscreen={true} />

  if (!selectedAccount) return null

  return (
    <>
      <Row justify='space-between' align={'middle'}>
        <h3>Account Update</h3>
        <Link to={'/accounts'}>
          <Button>Back</Button>
        </Link>
      </Row>
      <AccountForm />
    </>
  )
}
export default AccountEditPage
