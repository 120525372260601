import { useAppDispatch, useAppSelector } from 'hooks/redux'
import { Button, Col, Form, FormInstance, Row, Select, Spin } from 'antd'
import React, { useEffect, useState } from 'react'
import { addNotification } from 'features/notifications/notificationHandler'
import {
  createProductRebill,
  updateProductRebill,
  updateProductRebillStatus,
} from 'features/productRebills/productRebillsSlice'
import { ProductRebillData, RebillCycle } from 'features/productRebills/types'
import ProductRebillCycle from 'features/productRebills/components/productRebillCycle'
import { makeKey } from 'untils'

const ProductRebillForm = ({ form }: { form: FormInstance }) => {
  const dispatch = useAppDispatch()
  const { selectedProduct } = useAppSelector(state => state.productReducer)
  const { error, loading, selectedProductRebill, productRebills } = useAppSelector(state => state.productRebillReducer)

  const [cycles, setCycles] = useState<RebillCycle[]>([])

  useEffect(() => {
    if (error) {
      dispatch(
        addNotification({
          type: 'error',
          message: error,
        }),
      )
    }
  }, [error])

  useEffect(() => {
    if (selectedProductRebill?.id) {
      setCycles(
        selectedProductRebill.cycles.map(cycle => ({
          key: makeKey(),
          step: cycle.step,
          cycleAttempts: cycle.cycleAttempts.map(attempt => ({
            key: makeKey(),
            delayMin: attempt.delayMin,
            delayMax: attempt.delayMax,
            price: +attempt.price,
            quantity: attempt.quantity,
            step: attempt.step,
          })),
        })),
      )
    }
  }, [selectedProductRebill])

  const formSubmit = async (values: ProductRebillData) => {
    if (!selectedProduct) {
      return
    }
    values.productId = selectedProduct.id
    values.active = false
    values.cycles = cycles
    if (selectedProductRebill?.id) {
      values.active = selectedProductRebill.active
      dispatch(updateProductRebill({ id: selectedProductRebill.id, ...values })).then(response => {
        if (response.payload) {
          dispatch(
            addNotification({
              type: 'success',
              message: 'Product Rebill updated successfully',
            }),
          )
        }
      })
    } else {
      dispatch(createProductRebill(values)).then(response => {
        if (response.payload) {
          dispatch(
            addNotification({
              type: 'success',
              message: 'Product Rebill created successfully',
            }),
          )
        }
      })
    }
  }
  const onDelete = (record: RebillCycle) => {
    setCycles([...cycles.filter(cycle => cycle !== record)])
  }
  const onCreateCycle = () => {
    setCycles([
      ...cycles,
      {
        key: makeKey(),
        step: cycles.length,
        cycleAttempts: [
          {
            key: makeKey(),
            delayMin: 1,
            step: 0,
            delayMax: 1,
            quantity: 1,
            price: 0,
          },
        ],
      },
    ])
  }
  const onUpdateStatus = (status: boolean) => {
    if (selectedProduct !== null && selectedProductRebill) {
      dispatch(updateProductRebillStatus({ id: selectedProductRebill.id, active: status })).then(response => {
        if (response.payload) {
          dispatch(
            addNotification({
              type: 'success',
              message: status ? 'Activated successfully' : 'Paused successfully',
            }),
          )
        }
      })
    }
  }

  if (loading) return <Spin />
  if (!selectedProduct) return null
  return (
    <>
      <Form form={form} onFinish={formSubmit} labelCol={{ span: 24, offset: 0 }} wrapperCol={{ span: 24, offset: 0 }}>
        <Row>
          <Col sm={12} xs={24}>
            <div>
              Status:{' '}
              {selectedProductRebill?.active ? (
                <span className={'green'}>Active</span>
              ) : (
                <span className={'red'}>Disabled</span>
              )}
            </div>
            <Button disabled={!selectedProductRebill} onClick={() => onUpdateStatus(!selectedProductRebill?.active)}>
              {selectedProductRebill?.active ? 'Disable' : 'Activate'}
            </Button>
          </Col>
          <Col sm={12} xs={24}>
            <Form.Item
              label={'Repeat Type'}
              name={'repeatType'}
              rules={[{ required: true, message: 'Please Select Repeat Type' }]}
              initialValue={selectedProductRebill?.repeatType}
            >
              <Select
                options={[
                  {
                    value: 0,
                    label: 'Loop',
                  },
                  {
                    value: 1,
                    label: 'Repeat Last',
                  },
                  {
                    value: 2,
                    label: 'Stop After Last',
                  },
                ]}
              />
            </Form.Item>
          </Col>
        </Row>
        <h4>Cycles:</h4>
        {cycles.map(cycle => (
          <ProductRebillCycle form={form} key={cycle.key} cycle={cycle} />
        ))}
        <Row>
          <Button style={{ width: '100%', marginTop: 20, marginBottom: 20 }} onClick={() => onCreateCycle()}>
            Add Cycle
          </Button>
        </Row>
      </Form>
    </>
  )
}
export default ProductRebillForm
