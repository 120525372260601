import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import axiosInstance from 'untils'
import { AxiosResponse } from 'axios'
import { ProductAttempt, ProductAttemptData, ProductAttemptState } from 'features/productAttempts/types'
const productAttemptState: ProductAttemptState = {
  selectedProductAttempt: null,
  productAttempts: [],
  total: 0,
  error: null,
  loading: false,
}
export const getProductAttempts = createAsyncThunk<
  { items: ProductAttempt[]; total: number },
  number,
  { rejectValue: void }
>('getProductAttemptsList', async productId => {
  const response = await axiosInstance.get<void, AxiosResponse<{ items: ProductAttempt[]; total: number }>>(
    `/productAttempt`,
    {
      params: {
        productId,
      },
    },
  )
  return response.data
})

export const getProductAttempt = createAsyncThunk<ProductAttempt, number, { rejectValue: void }>(
  'getProductAttempt',
  async id => {
    const response = await axiosInstance.get<void, AxiosResponse<ProductAttempt>>(`/productAttempt/${id}`)
    return response.data
  },
)

export const createProductAttempt = createAsyncThunk<ProductAttempt, ProductAttemptData, { rejectValue: void }>(
  'createProductAttempt',
  async productAttempt => {
    const response = await axiosInstance.post<void, AxiosResponse<ProductAttempt>>(`/productAttempt`, productAttempt)
    return response.data
  },
)

export const updateProductAttempt = createAsyncThunk<ProductAttempt, ProductAttempt, { rejectValue: void }>(
  'updateProductAttempt',
  async productAttempt => {
    const response = await axiosInstance.put<void, AxiosResponse<ProductAttempt>>(
      `/productAttempt/${productAttempt.id}`,
      productAttempt,
    )
    return response.data
  },
)
export const updateProductAttemptStatus = createAsyncThunk<
  ProductAttempt,
  { id: number; active: boolean },
  { rejectValue: void }
>('updateProductAttemptStatus', async ({ id, active }) => {
  const response = await axiosInstance.put<void, AxiosResponse<ProductAttempt>>(`/productAttempt/${id}/statusUpdate`, {
    active,
  })
  return response.data
})

export const deleteProductAttempt = createAsyncThunk<boolean, number, { rejectValue: void }>(
  'deleteProductAttempt',
  async (id, { dispatch }) => {
    const response = await axiosInstance.delete<void, AxiosResponse<boolean>>(`/productAttempt/${id}`)
    dispatch(checkSelectedProductAttempt(id))
    return response.data
  },
)

export const productAttemptAttemptsSlice = createSlice({
  name: 'productAttempt',
  initialState: productAttemptState,
  reducers: {
    resetProductAttempt(state) {
      state.selectedProductAttempt = null
    },
    checkSelectedProductAttempt(state, action) {
      if (state.selectedProductAttempt?.id === action.payload) {
        state.selectedProductAttempt = null
      }
    },
  },
  extraReducers: builder => {
    builder.addCase(getProductAttempts.pending, state => {
      state.loading = true
      state.error = null
    })
    builder.addCase(getProductAttempts.rejected, (state, action) => {
      state.error = action.error.message ? action.error.message : 'Unknown error'
      state.loading = false
      state.productAttempts = []
    })
    builder.addCase(getProductAttempts.fulfilled, (state, action) => {
      state.error = null
      state.loading = false
      state.productAttempts = action.payload.items
    })

    builder.addCase(getProductAttempt.pending, state => {
      state.loading = true
      state.error = null
    })
    builder.addCase(getProductAttempt.rejected, (state, action) => {
      state.error = action.error.message ? action.error.message : 'Unknown error'
      state.loading = false
      state.selectedProductAttempt = null
    })
    builder.addCase(getProductAttempt.fulfilled, (state, action) => {
      state.error = null
      state.loading = false
      state.selectedProductAttempt = action.payload
    })

    builder.addCase(createProductAttempt.pending, state => {
      state.loading = true
      state.error = null
    })
    builder.addCase(createProductAttempt.rejected, (state, action) => {
      state.error = action.error.message ? action.error.message : 'Unknown error'
      state.loading = false
      state.selectedProductAttempt = null
    })
    builder.addCase(createProductAttempt.fulfilled, (state, action) => {
      state.error = null
      state.loading = false
      state.selectedProductAttempt = action.payload
    })

    builder.addCase(updateProductAttempt.pending, state => {
      state.loading = true
      state.error = null
    })
    builder.addCase(updateProductAttempt.rejected, (state, action) => {
      state.error = action.error.message ? action.error.message : 'Unknown error'
      state.loading = false
    })
    builder.addCase(updateProductAttempt.fulfilled, (state, action) => {
      state.error = null
      state.loading = false
      state.selectedProductAttempt = action.payload
    })

    builder.addCase(deleteProductAttempt.pending, state => {
      state.loading = true
      state.error = null
    })
    builder.addCase(deleteProductAttempt.rejected, (state, action) => {
      state.error = action.error.message ? action.error.message : 'Unknown error'
      state.loading = false
    })
    builder.addCase(deleteProductAttempt.fulfilled, (state, action) => {
      state.error = null
      state.loading = false
    })

    builder.addCase(updateProductAttemptStatus.fulfilled, (state, action) => {
      state.error = null
      state.loading = false
    })
    builder.addCase(updateProductAttemptStatus.pending, state => {
      state.loading = true
      state.error = null
    })
    builder.addCase(updateProductAttemptStatus.rejected, (state, action) => {
      state.error = action.error.message ? action.error.message : 'Unknown error'
      state.loading = false
    })
  },
})
export const { resetProductAttempt, checkSelectedProductAttempt } = productAttemptAttemptsSlice.actions
export default productAttemptAttemptsSlice.reducer
