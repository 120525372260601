import { useAppDispatch, useAppSelector } from 'hooks/redux'
import { Button, Col, Popconfirm, Row, Select, Spin, Table, Tooltip } from 'antd'
import { Link, useNavigate, useParams } from 'react-router-dom'
import React, { useEffect, useState } from 'react'
import {
  deleteProductAttempt,
  getProductAttempt,
  getProductAttempts,
  resetProductAttempt,
  updateProductAttemptStatus,
} from 'features/productAttempts/productAttemptsSlice'

import { addNotification } from 'features/notifications/notificationHandler'
import { getProduct } from 'features/product/productSlice'
import ProductAttemptModal from 'features/productAttempts/components/productAttemptModal'
import { DeleteFilled, EditFilled, FieldTimeOutlined, PauseCircleFilled, PlayCircleOutlined } from '@ant-design/icons'
import { timeUnit } from 'untils/enums'

const ProductAttemptsList = () => {
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const [open, setOpen] = React.useState(false)
  const { error, loading, productAttempts, total } = useAppSelector(state => state.productAttemptReducer)
  const { selectedProduct, error: productError } = useAppSelector(state => state.productReducer)
  const { id } = useParams()

  useEffect(() => {
    if (id && +id > 0) {
      dispatch(getProduct(+id))
    }
  }, [id])

  useEffect(() => {
    if (selectedProduct !== null) {
      dispatch(getProductAttempts(selectedProduct.id))
    }
  }, [selectedProduct])

  useEffect(() => {
    if (error) {
      dispatch(
        addNotification({
          type: 'error',
          message: error,
        }),
      )
    }
    if (productError) {
      dispatch(
        addNotification({
          type: 'error',
          message: productError,
        }),
      )
      navigate('/products')
    }
  }, [error, productError])

  const toggle = () => {
    setOpen(!open)
  }

  const onEdit = (id: number) => {
    if (selectedProduct !== null) {
      dispatch(getProductAttempt(id)).then(response => {
        if (response.payload) {
          toggle()
        }
      })
    }
  }
  const onDelete = (id: number) => {
    if (selectedProduct !== null) {
      dispatch(deleteProductAttempt(id)).then(response => {
        if (response.payload) {
          dispatch(
            addNotification({
              type: 'success',
              message: 'Product Attempt removed successfully',
            }),
          )
          dispatch(getProductAttempts(selectedProduct.id))
        }
      })
    }
  }

  const onUpdateStatus = (id: number, status: boolean) => {
    if (selectedProduct !== null) {
      dispatch(updateProductAttemptStatus({ id, active: status })).then(response => {
        if (response.payload) {
          dispatch(
            addNotification({
              type: 'success',
              message: status ? 'Activated successfully' : 'Paused successfully',
            }),
          )
          dispatch(getProductAttempts(selectedProduct.id))
        }
      })
    }
  }

  const createProductAttempt = () => {
    dispatch(resetProductAttempt())
    toggle()
  }

  if (!selectedProduct) return null

  return (
    <>
      <Row justify='space-between' align={'middle'}>
        <h3>
          Product Attempts ({selectedProduct.name} {selectedProduct.externalId})
        </h3>
        <Row gutter={8}>
          <Col>
            <Link to={'/products'}>
              <Button>Back</Button>
            </Link>
          </Col>
          <Col>
            <Button disabled={productAttempts?.length > 2} onClick={createProductAttempt} type={'primary'}>
              Create Attempt
            </Button>
          </Col>
        </Row>
      </Row>
      <ProductAttemptModal open={open} toggle={toggle} />
      {loading && <Spin />}
      {!loading &&
        selectedProduct.id &&
        productAttempts.map(attempt => {
          let minDelay = attempt.delayMin
          let maxDelay = attempt.delayMax
          let delayTimeUnit: 'min' | 'hour' | 'day' = 'min'
          if (minDelay % 60 === 0) {
            minDelay = minDelay / 60
            maxDelay = maxDelay / 60
            delayTimeUnit = 'hour'
          }
          if (minDelay % 24 === 0) {
            minDelay = minDelay / 24
            maxDelay = maxDelay / 24
            delayTimeUnit = 'day'
          }
          return (
            <Row key={attempt.id} className={'productAttempt'} justify={'start'} align={'middle'}>
              <span className={'productAttempt__step'}>{attempt.step + 1}</span>
              <div>
                <div>
                  <FieldTimeOutlined className={'delay'} /> Min Delay: {minDelay} {timeUnit[delayTimeUnit]}
                </div>
                <div>
                  <FieldTimeOutlined className={'delay'} /> Max Delay: {maxDelay} {timeUnit[delayTimeUnit]}
                </div>
              </div>

              <div className={'productAttempt__price'}>
                <span className={'currency'}>$</span>
                {Number(attempt.price) !== 0 ? attempt.price : 'Auto'}
              </div>
              <div className={'productAttempt__quantity'}>
                <span>Qty:</span> {attempt.quantity}
              </div>

              <div className={'productAttempt__actions actions'}>
                {!attempt.active && (
                  <Tooltip title={'Activate'} placement={'top'}>
                    <PlayCircleOutlined onClick={() => onUpdateStatus(attempt.id, true)} />
                  </Tooltip>
                )}
                {attempt.active && (
                  <Tooltip title={'Disable'} placement={'top'}>
                    <PauseCircleFilled onClick={() => onUpdateStatus(attempt.id, false)} />
                  </Tooltip>
                )}
                <Tooltip title={'Edit'} placement={'top'}>
                  <EditFilled onClick={() => onEdit(attempt.id)} />
                </Tooltip>
                <Tooltip title={'Delete'} placement={'top'}>
                  <Popconfirm
                    title='Delete Product Attempt'
                    description='Confirm Product Attempt remove'
                    onConfirm={() => onDelete(attempt.id)}
                    okText='Confirm'
                    cancelText='Cancel'
                  >
                    <DeleteFilled />
                  </Popconfirm>
                </Tooltip>
              </div>
            </Row>
          )
        })}
    </>
  )
}
export default ProductAttemptsList
