import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import axiosInstance from 'untils'
import { AxiosResponse } from 'axios'
import { ProductRebill, ProductRebillData, ProductRebillState } from 'features/productRebills/types'
const productRebillState: ProductRebillState = {
  selectedProductRebill: null,
  productRebills: [],
  total: 0,
  error: null,
  loading: false,
}
export const getProductRebills = createAsyncThunk<
  { items: ProductRebill[]; total: number },
  number,
  { rejectValue: void }
>('getProductRebillsList', async productId => {
  const response = await axiosInstance.get<void, AxiosResponse<{ items: ProductRebill[]; total: number }>>(
    `/productRebill`,
    {
      params: {
        productId,
      },
    },
  )
  return response.data
})

export const getProductRebill = createAsyncThunk<ProductRebill, number, { rejectValue: void }>(
  'getProductRebill',
  async id => {
    const response = await axiosInstance.get<void, AxiosResponse<ProductRebill>>(`/productRebill/${id}`)
    return response.data
  },
)

export const createProductRebill = createAsyncThunk<ProductRebill, ProductRebillData, { rejectValue: void }>(
  'createProductRebill',
  async productRebill => {
    const response = await axiosInstance.post<void, AxiosResponse<ProductRebill>>(`/productRebill`, productRebill)
    return response.data
  },
)

export const updateProductRebill = createAsyncThunk<ProductRebill, ProductRebill, { rejectValue: void }>(
  'updateProductRebill',
  async productRebill => {
    const response = await axiosInstance.put<void, AxiosResponse<ProductRebill>>(
      `/productRebill/${productRebill.id}`,
      productRebill,
    )
    return response.data
  },
)
export const updateProductRebillStatus = createAsyncThunk<
  ProductRebill,
  { id: number; active: boolean },
  { rejectValue: void }
>('updateProductRebillStatus', async ({ id, active }) => {
  const response = await axiosInstance.put<void, AxiosResponse<ProductRebill>>(`/productRebill/${id}/statusUpdate`, {
    active,
  })
  return response.data
})

export const deleteProductRebill = createAsyncThunk<boolean, number, { rejectValue: void }>(
  'deleteProductRebill',
  async (id, { dispatch }) => {
    const response = await axiosInstance.delete<void, AxiosResponse<boolean>>(`/productRebill/${id}`)
    dispatch(checkSelectedProductRebill(id))
    return response.data
  },
)

export const productRebillRebillsSlice = createSlice({
  name: 'productRebill',
  initialState: productRebillState,
  reducers: {
    resetProductRebill(state) {
      state.selectedProductRebill = null
    },
    checkSelectedProductRebill(state, action) {
      if (state.selectedProductRebill?.id === action.payload) {
        state.selectedProductRebill = null
      }
    },
  },
  extraReducers: builder => {
    builder.addCase(getProductRebills.pending, state => {
      state.loading = true
      state.error = null
    })
    builder.addCase(getProductRebills.rejected, (state, action) => {
      state.error = action.error.message ? action.error.message : 'Unknown error'
      state.loading = false
      state.productRebills = []
    })
    builder.addCase(getProductRebills.fulfilled, (state, action) => {
      state.error = null
      state.loading = false
      state.productRebills = action.payload.items
    })

    builder.addCase(getProductRebill.pending, state => {
      state.loading = true
      state.error = null
    })
    builder.addCase(getProductRebill.rejected, (state, action) => {
      //state.error = action.error.message ? action.error.message : 'Unknown error'
      state.loading = false
      state.selectedProductRebill = null
    })
    builder.addCase(getProductRebill.fulfilled, (state, action) => {
      state.error = null
      state.loading = false
      state.selectedProductRebill = action.payload
    })

    builder.addCase(createProductRebill.pending, state => {
      state.loading = true
      state.error = null
    })
    builder.addCase(createProductRebill.rejected, (state, action) => {
      state.error = action.error.message ? action.error.message : 'Unknown error'
      state.loading = false
      state.selectedProductRebill = null
    })
    builder.addCase(createProductRebill.fulfilled, (state, action) => {
      state.error = null
      state.loading = false
      state.selectedProductRebill = action.payload
    })

    builder.addCase(updateProductRebill.pending, state => {
      state.loading = true
      state.error = null
    })
    builder.addCase(updateProductRebill.rejected, (state, action) => {
      state.error = action.error.message ? action.error.message : 'Unknown error'
      state.loading = false
    })
    builder.addCase(updateProductRebill.fulfilled, (state, action) => {
      state.error = null
      state.loading = false
      state.selectedProductRebill = action.payload
    })

    builder.addCase(deleteProductRebill.pending, state => {
      state.loading = true
      state.error = null
    })
    builder.addCase(deleteProductRebill.rejected, (state, action) => {
      state.error = action.error.message ? action.error.message : 'Unknown error'
      state.loading = false
    })
    builder.addCase(deleteProductRebill.fulfilled, (state, action) => {
      state.error = null
      state.loading = false
    })

    builder.addCase(updateProductRebillStatus.fulfilled, (state, action) => {
      state.error = null
      state.loading = false
      state.selectedProductRebill = action.payload
    })
    builder.addCase(updateProductRebillStatus.pending, state => {
      state.loading = true
      state.error = null
    })
    builder.addCase(updateProductRebillStatus.rejected, (state, action) => {
      state.error = action.error.message ? action.error.message : 'Unknown error'
      state.loading = false
    })
  },
})
export const { resetProductRebill, checkSelectedProductRebill } = productRebillRebillsSlice.actions
export default productRebillRebillsSlice.reducer
