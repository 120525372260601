import { useAppDispatch, useAppSelector } from 'hooks/redux'
import { Button, Col, Form, Input, Row, Select } from 'antd'
import { login, signUp } from 'features/user/userSlice'
import { useNavigate } from 'react-router-dom'
import React, { useEffect, useState } from 'react'
import { OfferData } from 'features/offer/types'
import { createOffer, updateOffer } from 'features/offer/offerSlice'
import { CRMType } from 'untils/enums'
import { addNotification } from 'features/notifications/notificationHandler'
import { getAccountsListSelect } from 'features/account/accountSlice'
import { saveToCookies } from 'untils/saveToCookies'
import { getCampaignsListSelect } from 'features/campaign/campaignSlice'

const OfferForm = () => {
  const dispatch = useAppDispatch()
  const { error, loading, selectedOffer } = useAppSelector(state => state.offerReducer)
  const { error: accountError, loading: accountLoading, accountsSelect } = useAppSelector(state => state.accountReducer)
  const {
    error: campaignError,
    loading: campaignLoading,
    campaignsSelect,
  } = useAppSelector(state => state.campaignReducer)
  const navigate = useNavigate()
  const [accountId, setAccountId] = useState<null | number>(null)

  useEffect(() => {
    dispatch(getAccountsListSelect({}))
  }, [])
  useEffect(() => {
    if (selectedOffer) {
      setAccountId(selectedOffer.accountId)
    }
  }, [selectedOffer])
  useEffect(() => {
    if (accountId) {
      dispatch(getCampaignsListSelect(accountId))
    }
  }, [accountId])

  useEffect(() => {
    if (error) {
      dispatch(
        addNotification({
          type: 'error',
          message: error,
        }),
      )
    }
  }, [error])

  const formSubmit = async (values: OfferData) => {
    if (selectedOffer?.id) {
      dispatch(updateOffer({ id: selectedOffer.id, ...values })).then(response => {
        if (response.payload) {
          dispatch(
            addNotification({
              type: 'success',
              message: 'Offer updated successfully',
            }),
          )
        }
      })
    } else {
      dispatch(createOffer(values)).then(response => {
        if (response.payload) {
          dispatch(
            addNotification({
              type: 'success',
              message: 'Offer created successfully',
            }),
          )
          navigate(`/offer/${response.payload.id}`)
        }
      })
    }
  }
  return (
    <>
      <Form onFinish={formSubmit} labelCol={{ span: 24, offset: 0 }}>
        <Row>
          <Col span={24}>
            <Form.Item
              label={'Name'}
              name={'name'}
              rules={[{ required: true, message: 'Please Enter Name' }]}
              initialValue={selectedOffer?.name}
            >
              <Input placeholder={'Name'} />
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col span={12}>
            <Form.Item
              label={'CRM Account'}
              name={'accountId'}
              rules={[{ required: true, message: 'Please Select Name' }]}
              initialValue={selectedOffer?.accountId}
            >
              <Select
                loading={accountLoading}
                style={{ width: '100%' }}
                placeholder={'Account'}
                options={accountsSelect.map(account => ({ label: account.name, value: account.id }))}
                onChange={value => setAccountId(value)}
              />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              label={'Campaign'}
              name={'campaignId'}
              rules={[{ required: true, message: 'Please Select Campaign' }]}
              initialValue={selectedOffer?.accountId}
            >
              <Select
                loading={campaignLoading}
                style={{ width: '100%' }}
                placeholder={'Campaign'}
                options={campaignsSelect.map(campaign => ({
                  label: `${campaign.name} (${campaign.externalId})}`,
                  value: campaign.id,
                }))}
              />
            </Form.Item>
          </Col>
        </Row>
        {selectedOffer?.guid && (
          <Row>
            <Col span={24}>
              <Form.Item label={'GUID'} name={'guid'} initialValue={selectedOffer?.guid}>
                <Input disabled={true} />
              </Form.Item>
            </Col>
          </Row>
        )}

        <Button type={'primary'} htmlType={'submit'} loading={loading}>
          {selectedOffer?.id ? 'Update' : 'Create'}
        </Button>
      </Form>
    </>
  )
}
export default OfferForm
