import { useAppDispatch, useAppSelector } from 'hooks/redux'
import { Button, Col, Form, Input, Row, Select } from 'antd'
import { login, signUp } from 'features/user/userSlice'
import { useNavigate } from 'react-router-dom'
import React, { useEffect, useState } from 'react'
import { AccountData } from 'features/account/types'
import { createAccount, updateAccount } from 'features/account/accountSlice'
import { CRMType } from 'untils/enums'
import { addNotification } from 'features/notifications/notificationHandler'

const AccountForm = () => {
  const dispatch = useAppDispatch()
  const { error, loading, selectedAccount } = useAppSelector(state => state.accountReducer)
  const navigate = useNavigate()
  const [crmType, setCrmType] = useState<null | number>(null)

  useEffect(() => {
    if (selectedAccount?.id) {
      setCrmType(selectedAccount.crmType)
    }
  }, [selectedAccount])

  useEffect(() => {
    if (error) {
      dispatch(
        addNotification({
          type: 'error',
          message: error,
        }),
      )
    }
  }, [error])

  const formSubmit = async (values: AccountData) => {
    if (selectedAccount?.id) {
      dispatch(updateAccount({ id: selectedAccount.id, ...values })).then(response => {
        if (response.payload) {
          dispatch(
            addNotification({
              type: 'success',
              message: 'Account updated successfully',
            }),
          )
        }
      })
    } else {
      dispatch(createAccount(values)).then(response => {
        if (response.payload) {
          dispatch(
            addNotification({
              type: 'success',
              message: 'Account created successfully',
            }),
          )
          navigate(`/account/${response.payload.id}`)
        }
      })
    }
  }
  return (
    <>
      <Form onFinish={formSubmit} labelCol={{ span: 24, offset: 0 }}>
        <Row>
          <Col span={24}>
            <Form.Item
              label={'Name'}
              name={'name'}
              rules={[{ required: true, message: 'Please Enter Name' }]}
              initialValue={selectedAccount?.name}
            >
              <Input placeholder={'Name'} />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col sm={12} xs={24}>
            <Form.Item
              label={'CRM Type'}
              name={'crmType'}
              rules={[{ required: true, message: 'PleaseSelect CRM Type' }]}
              initialValue={selectedAccount?.crmType}
            >
              <Select
                placeholder={'Please Select CRM Type'}
                options={[
                  { label: 'Response', value: CRMType.response },
                  { label: 'Konnektive', value: CRMType.konnektive },
                  { label: 'Sticky', value: CRMType.sticky },
                  { label: 'Sublytics', value: CRMType.sublytics },
                ]}
                onChange={(value, option) => {
                  setCrmType(value)
                }}
              ></Select>
            </Form.Item>
          </Col>
          <Col sm={12} xs={24}>
            <Form.Item label={'Rebatez Key'} name={'rebatezKey'} initialValue={selectedAccount?.rebatezKey}>
              <Input placeholder={'Rebatez Key'} />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={16}>
          {crmType === CRMType.response && (
            <Col sm={12} xs={24}>
              {' '}
              <Form.Item label={'GUID'} name={'crmGuid'} initialValue={selectedAccount?.crmGuid}>
                <Input placeholder={'GUID'} />
              </Form.Item>
            </Col>
          )}
          <Col sm={12} xs={24}>
            <Form.Item label={'3DS Key'} name={'threeDSKey'} initialValue={selectedAccount?.threeDSKey}>
              <Input placeholder={'3DS Key'} />
            </Form.Item>
          </Col>
        </Row>
        {crmType !== CRMType.response && crmType !== null && (
          <Row gutter={16}>
            <Col sm={12} xs={24}>
              <Form.Item label={'CRM Login'} name={'crmLogin'} initialValue={selectedAccount?.crmLogin}>
                <Input placeholder={'Login'} />
              </Form.Item>
            </Col>
            <Col sm={12} xs={24}>
              <Form.Item label={'CRM Password'} name={'crmPassword'} initialValue={selectedAccount?.crmPassword}>
                <Input placeholder={'Password'} />
              </Form.Item>
            </Col>
          </Row>
        )}

        <Button type={'primary'} htmlType={'submit'} loading={loading}>
          {selectedAccount?.id ? 'Update' : 'Create'}
        </Button>
      </Form>
    </>
  )
}
export default AccountForm
