import { useAppDispatch, useAppSelector } from 'hooks/redux'
import React, { useEffect, useState } from 'react'
import { getAvailableProducts, resetLoadBalancerRule } from 'features/loadBalancer/loadBalancerSlice'
import LoadBalancerRuleForm from 'features/loadBalancer/components/loadBalancerForm'
import { Button, Row, Spin } from 'antd'
import { Link, useParams } from 'react-router-dom'
import { getLoadBalancerRule } from 'features/loadBalancer/loadBalancerSlice'
import { getMidsListSelect } from 'features/mid/midSlice'
import Cookies from 'js-cookie'

const LoadBalancerRuleEditPage = () => {
  const dispatch = useAppDispatch()
  const { error, loading, selectedLoadBalancerRule } = useAppSelector(state => state.loadBalancerReducer)
  const selectedAccount = Cookies.get('activeAccountId')
  const { id } = useParams()
  useEffect(() => {
    if (id && +id > 0) {
      dispatch(getLoadBalancerRule(+id))
    }
  }, [id])
  useEffect(() => {
    if (selectedAccount && id && +id > 0) {
      dispatch(getAvailableProducts({ accountId: +selectedAccount, ruleId: +id }))
      dispatch(getMidsListSelect(+selectedAccount))
    }
  }, [selectedAccount, id])

  if (loading) return <Spin fullscreen={true} />

  if (!selectedLoadBalancerRule) return null

  return (
    <>
      <Row justify='space-between' align={'middle'}>
        <h3>Load Balancer Rule Update</h3>
        <Link to={'/loadBalancerRules'}>
          <Button>Back</Button>
        </Link>
      </Row>
      <LoadBalancerRuleForm />
    </>
  )
}
export default LoadBalancerRuleEditPage
