import { useAppDispatch, useAppSelector } from 'hooks/redux'
import { Button, Col, Form, FormInstance, Input, InputNumber, Modal, Popconfirm, Row, Select, Table } from 'antd'
import { useNavigate } from 'react-router-dom'
import React, { useEffect, useState } from 'react'
import { ProductData } from 'features/product/types'
import { addNotification } from 'features/notifications/notificationHandler'
import {
  createProductRebill,
  getProductRebills,
  updateProductRebill,
} from 'features/productRebills/productRebillsSlice'
import { ProductRebillData, RebillCycle, RebillCycleAttempt } from 'features/productRebills/types'
import { Account } from 'features/account/types'
import { CRMName } from 'untils/enums'
import { DeleteFilled, EditFilled, FieldTimeOutlined, PauseCircleFilled, PlayCircleOutlined } from '@ant-design/icons'
import { deleteAccount, getAccountsList } from 'features/account/accountSlice'
import ProductRebillCycleAttempt from 'features/productRebills/components/productRebillCycleAttempt'
import { makeKey } from 'untils'

const ProductRebillCycle = ({ cycle, form }: { cycle: RebillCycle; form: FormInstance }) => {
  const { selectedProductRebill } = useAppSelector(state => state.productRebillReducer)
  const [attempts, setAttempts] = useState<RebillCycleAttempt[]>([])
  const [allowDelete, setAllowDelete] = React.useState<boolean>(false)

  useEffect(() => {
    if (cycle) {
      setAttempts(cycle.cycleAttempts)
      if (cycle.cycleAttempts?.length > 1) {
        setAllowDelete(true)
      }
    }
  }, [cycle])
  useEffect(() => {
    if (cycle && attempts.length) {
      cycle.cycleAttempts = attempts
    }
  }, [attempts])

  const onDelete = (record: RebillCycleAttempt) => {
    if (cycle) {
      setAttempts([
        ...attempts
          .filter(attempt => attempt !== record)
          .map(attempt => {
            if (attempt.step > record.step) {
              attempt.step--
            }
            return attempt
          }),
      ])
      if (attempts.length < 2) {
        setAllowDelete(false)
      }
    }
  }
  const onCreate = () => {
    if (cycle) {
      if (attempts.length < 5) {
        setAttempts([
          ...attempts,
          {
            key: makeKey(),
            delayMin: 1,
            step: attempts.length,
            delayMax: 1,
            price: 0,
            quantity: 1,
          },
        ])
      }
      setAllowDelete(true)
    }
  }

  return (
    <div className={'productRebillCycle'}>
      <h4>Cycle#: {cycle.step + 1}</h4>
      <h4>Attempts:</h4>

      {attempts.map(attempt => (
        <ProductRebillCycleAttempt
          form={form}
          key={attempt.key}
          attempt={attempt}
          onDelete={onDelete}
          allowDelete={allowDelete}
        />
      ))}
      <Row>
        <Button style={{ width: '100%', marginTop: 20 }} onClick={() => onCreate()} disabled={attempts.length > 4}>
          Add Attempt
        </Button>
      </Row>
    </div>
  )
}
export default ProductRebillCycle
